export const getOSName = () => {
    const userAgent = navigator.userAgent;

    const conditions = [
        { key: "Win", name: "Windows OS" },
        { key: "Mac", name: "Macintosh" },
        { key: "Linux", name: "Linux OS" },
        { key: "Android", name: "Android OS" },
        { key: "like Mac", name: "iOS" }
    ];

    let osName = "Unknown OS";

    conditions.some(condition => {
        switch (true) {
            case userAgent.indexOf(condition.key) !== -1:
                osName = condition.name;
                return true;
        }
    });

    return osName;
};

export const formatSecondsToMinutes = (timeInSeconds) => {
    let minutes = Math.floor(timeInSeconds / 60);
    let seconds = Math.floor(timeInSeconds % 60);

    if (isNaN(minutes)) {
        minutes = 0;
    }

    if (isNaN(seconds)) {
        seconds = 0;
    }

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}

export const disableReactDevTools = () => {
    document.addEventListener('contextmenu', (e) => e.preventDefault());

    function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    }

    document.onkeydown = (e) => {
        // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        if (
            e.keyCode === 123 ||
            ctrlShiftKey(e, 'I') ||
            ctrlShiftKey(e, 'J') ||
            ctrlShiftKey(e, 'C') ||
            (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
        )
            return false;
    };
}