import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import InsertDriveFileIcon from "@mui/icons-material/UploadFile";
import UploadFileIcon from "@mui/icons-material/UploadFile"; 
import { TalentAssessmentUploadChunks, TalentAssessmentUploadComplete } from "../../../../Constants/ConstantsUrl";
import axios from "axios";
import Loader from "../../../Common/Loader";
import Alerts from "../../../Common/Alerts";
import CustomLoader from "../../../Common/CustomLoader";

function FileUpload(props) {
    const { data, count, handleFileUploadupdate, loderval, setLoader, setPauseTime } = props;
    const [selectedFile, setSelectedFile] = useState(null);
    const [alertNotification, setAlertNotification] = useState({
        status: false,
        type: "error",
        message: "Success!!",
    });
    const FileSize=200;
    
     
    const handleFileUpload = async (e) => {
        var elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen().catch(() => {});
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        }
        const file = e.target.files[0];
        const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif", "image/svg+xml", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv", "application/vnd.ms-excel"];    
        if (file && !allowedTypes.includes(file.type)) {
            setAlertNotification({
                status: true,
                type: "failed",
                message: "Unsupported file type",
            })
            return false;
        }
        setSelectedFile(file);

        const fileMaxSize = FileSize * 1024 * 1024; // 5MB 
        if(file.size > fileMaxSize){   
            setAlertNotification({
                status: true,
                type: "failed",
                message: `Max File Size is ${FileSize}MB`,
            })
            return false;
    }
        setLoader(true);        
        const chunkSize = 5 * 1024 * 1024; // 5MB chunks (adjust as needed)
        const totalChunks = Math.ceil(file.size / chunkSize);

        setPauseTime(true);
        for (let currentChunk = 0; currentChunk < totalChunks; currentChunk++) {
            const start = currentChunk * chunkSize;
            const end = Math.min((currentChunk + 1) * chunkSize, file.size);

            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append("chunk", chunk);
            formData.append("currentChunk", currentChunk);
            formData.append("totalChunks", totalChunks);
            formData.append("filename", "file_upload_" + file.name);
            formData.append("file_type", "file_upload");

            try {
                await axios.post(TalentAssessmentUploadChunks, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });                
                // Update your progress bar here if needed
            } catch (error) {
                console.error("Error uploading chunk:", error);
                // Handle errors as needed
            }
        }        
        // Notify the server that all chunks have been uploaded
        await axios.post(TalentAssessmentUploadComplete, {
            filename: "file_upload_" + file.name,
            totalChunks: totalChunks,
            file_type: "file_upload",
        }).then((response) => response)
            .then((response) => {
                setLoader(false);
                setPauseTime(false);
                if (response.status === 200) {
                    handleFileUploadupdate(response.data.data.path, data.question_details.questions[count].id);
                }
            });
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif", "image/svg+xml", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv", "application/vnd.ms-excel"];    
        if (file && allowedTypes.includes(file.type)) {
            e.target = e.dataTransfer;
            handleFileUpload(e);
        } else {
            setAlertNotification({
                status: true,
                type: "failed",
                message: "Unsupported file type",
            })
        }
        
    };
    
    return (
        <Container
            maxWidth="lg"
            style={{
                padding: "0px",
                borderRadius: "12px",
            }}
        >
            <Box
                style={{
                    backgroundColor: "white",
                    padding: "55px",
                    boxShadow:
                        "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                            Question
                        </Typography>
                        <Grid>
                            <Typography
                                variant="body1"
                                sx={{ fontSize: "13px", fontWeight: 400, mt: 2 }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: data.question_details?.questions[count].question }} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item style={{ padding: "0px 24px" }} xs={12} md={6}>
                        <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                            Upload file
                        </Typography>
                        {alertNotification?.status ? <Alerts notification={alertNotification} setNotification={setAlertNotification} /> : ""}
                            <Grid
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    bgcolor: "#f0f0f0",
                                    border: "1px dashed #888",
                                    borderRadius: "8px",
                                    flexDirection: "column",
                                    textAlign: "center",
                                    padding: "16px 32px",
                                    height: 200,
                                }}
                                component="label"
                                variant="contained"                                 
                            >
                                    <>
                                        {loderval ? (
                                            <Grid>
                                                <CustomLoader/>
                                            </Grid>
                                        ):(
                                            <>
                                                <Grid>
                                                    {selectedFile ? (
                                                        <InsertDriveFileIcon sx={{ height: "30px" }} />
                                                    ) : (
                                                        <UploadFileIcon sx={{ height: "30px" }} />
                                                    )}
                                                </Grid>
                                                <Typography sx={{mt: 2, wordWrap:"break-word"}}>
                                                    {selectedFile ? (
                                                        `Selected file: ${selectedFile.name}`
                                                    ) : (
                                                        <>
                                                            Drag and drop or{" "}
                                                            <span style={{ fontWeight: 600 }}>browse</span> your files.
                                                            <Typography sx={{ mt: 2 }}>{FileSize} MB max file size</Typography>
                                                        </>
                                                    )}
                                                </Typography>
                                                <input className="videoUpload_input" type="file" onChange={(e) => handleFileUpload(e)} accept=".pdf, image/*, .xlsx, .csv, .xls" />
                                            </>
                                        )}
                                        
                                    </>
                            </Grid>
                        <Typography sx={{ mt: 2 }}>
                            <span style={{ fontWeight: 600 }}>Supported file types:</span> .csv, .xls, .xlsx, .pdf, .png, .jpg
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default FileUpload;
