import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Logo from "../../../Assests/Images/LOGO.png";
import { styled } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Paper from "@mui/material/Paper";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));
const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

function Index() {
    const navigate = useNavigate();
    const location = useLocation();
    const oldData = location.state?.data;
    const oldFormData = location.state?.formData;
        
    const handelNext = async () => {       
        if(oldData.intro_outro_video_path?.intro_video_path !==''){
           navigate("/intro-video", { state:{ data:oldData, formData: oldFormData}});
        }else{
           navigate("/assessment-details", { 
                state:  { 
                    data:oldData, 
                    formData: oldFormData
                }
            });
        }
    };
    return (
        <Box>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Instruction - TestnHire</title>
                        
                    </Helmet>
                </div>
            </HelmetProvider>
             <Grid>
                <Container maxWidth="xl">
                    <img
                        style={{ padding: "20px" }}
                        src={Logo}
                        alt="../../../../Assests/Images/FirstSectionImg.jpg"
                    />
                </Container>
            </Grid>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#f6f6f6",
                    minHeight: "90vh",
                    position: "relative",
                }}
            >
                <Container maxWidth="lg" style={{ backgroundColor: "white", padding: "0px" }}>
                    <Box
                        style={{
                            padding: "55px",
                            boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Item>
                                    <Typography variant="h2" sx={{ fontSize: "24px", fontWeight: 400, my: 3 }}>
                                        Hello <span style={{ fontSize: "22px", fontWeight: 700 }}>{oldFormData.firstName} {oldFormData.lastName},</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}>
                                    Thank you for applying and welcome to our assessment.
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}>
                                    Completing it will give you a chance to show off your skills and stand out from the crowd!
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}>
                                        {" "}
                                        Good luck!
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Item>
                                    <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                                    A few things before you start:
                                    </Typography>

                                    <Grid sx={{ my: 2, display: "flex" }}>
                                        <Grid>
                                            <li></li>
                                        </Grid>
                                        <Grid>
                                            <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                                            This assessment consists of <strong>{oldData.total_no_of_test} tests</strong>. It will take approximately <strong>{oldData.total_time_given} minutes</strong> to complete.
                                               </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid sx={{ my: 2, display: "flex" }}>
                                        <Grid>
                                            <li></li>
                                        </Grid>
                                        <Grid>
                                            <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>The assessment is timed. A timer is shown per test or per question.</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid sx={{ my: 2, display: "flex" }}>
                                        <Grid>
                                            <li></li>
                                        </Grid>
                                        <Grid>
                                            <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                                            Before each test there may be preview questions, <strong>be aware the timer for the test will have already started</strong>.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid sx={{ my: 2, display: "flex" }}>
                                        <Grid>
                                            <li></li>
                                        </Grid>
                                        <Grid>
                                            <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                                            Please allow the use of your camera/webcam and do not leave full-screen mode. Snapshots will be taken of you periodically during the assessment. These measures are taken to ensure fairness for everyone.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid sx={{ my: 2, display: "flex" }}>
                                        <Grid>
                                            <li></li>
                                        </Grid>
                                        <Grid>
                                            <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>Turn on your speakers or headphones (to play audio).</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid sx={{ my: 2, display: "flex" }}>
                                        <Grid>
                                            <li></li>
                                        </Grid>
                                        <Grid>
                                            <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>You are free to use a calculator, pen and paper.</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid sx={{ my: 2, display: "flex" }}>
                                        <Grid>
                                            <li></li>
                                        </Grid>
                                        <Grid>
                                            <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>We recommend completing the assessment in one go.</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{ display: "flex", justifyContent: "end" }}>
                                        <Button
                                            sx={{
                                                ...btnStyle,
                                                bgcolor: "var(--button-bg-color-assessment)",
                                                minWidth: "48px",
                                                minHeight: "48px",
                                                textTransform: "capitalize",
                                                mt: 5,
                                                mb: 2,
                                                "&:hover": {
                                                    bgcolor: "var(--button-hover-bg-color-assessment)",
                                                },
                                            }}
                                            onClick={handelNext}
                                            variant="contained"
                                        >
                                            Get started <ChevronRightIcon />
                                        </Button>
                                    </Grid>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                <Grid>
                    <img
                        style={{
                            position: "fixed",
                            bottom: "10px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            opacity: 0.35,
                        }}
                        src={Logo}
                        alt="../../../../Assests/Images/FirstSectionImg.jpg"
                    />
                </Grid>
            </Box>
        
        </Box>
    );
}

export default Index;
