import { Box, Button, Container, Grid, Typography, CircularProgress } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { uploadFileRegular } from "../../../../Constants/ConstantsUrl";
import Alerts from "../../../Common/Alerts";
import fixWebmDuration from 'fix-webm-duration'
import { RadioButtonChecked as RadioButtonCheckedIcon, Save as SaveIcon, ReportProblemOutlined as ReportProblemOutlinedIcon } from '@mui/icons-material';
import { formatSecondsToMinutes } from "../../../../utils/helper"
import { LoadingButton } from '@mui/lab';

function VideoRecording(props) {
    const { data, count, handleFileUploadupdate, setLoader, setPauseTime, videoRef, handelNextSubmit } = props;
    const [alertNotification, setAlertNotification] = useState({
        status: false,
        type: "error",
        message: "Success!!",
    });

    const childVideoRef = useRef(null);
    const childMediaRecorder = useRef(null);
    const startTime = useRef(null);
    const [videoPrams, setVideoPrams] = useState({
        isConnecting: true,
        videoDuration: 0,
        videoUrl: null,
        isRecording: false,
        isRecordingComplete: false,
        recordingDuration: 0,
        videoBlob: null,
        isCameraOn: false,
        recordedChunks: [],
        isUploading: false,
        isUploaded: false,
        timeLimit: 120,
        timeLimitTimeout: null,
        thereWasAnError: false,
    });

    const startChildRecording = async () => {
        try {
            let childVideo;
            if (!videoRef?.current?.srcObject) {
                const childMediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                childVideo = childVideoRef.current;
                childVideo.srcObject = childMediaStream;
                childVideo.play();
            } else {
                setTimeout(() => {
                    childVideo = childVideoRef.current;
                    childVideo.srcObject = videoRef.current.srcObject;
                    childVideo.play();
                }, 200)
            }
        } catch (error) {
            console.error('Error accessing camera', error);
        }
    }

    const checkCamera = async () => {
        try {
            // Check if mediaDevices is supported in the browser
            if ("mediaDevices" in navigator) {
                // Try to access the camera
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });

                // If successful, the camera is available                 
                startChildRecording(); //start recording   
                stream.getTracks().forEach((track) => track.stop());

                setVideoPrams((prev) => ({
                    ...prev,
                    isCameraOn: true,
                    thereWasAnError: false,
                }))
            } else {
                // mediaDevices not supported, camera availability is unknown
                setVideoPrams((prev) => ({
                    ...prev,
                    thereWasAnError: true,
                }))
            }

            setVideoPrams((prev) => ({
                ...prev,
                isConnecting: false,
            }))
        } catch (error) {
            // Permission denied or camera not available            
            setVideoPrams((prev) => ({
                ...prev,
                isConnecting: false,
                thereWasAnError: true,
            }))
        }
    };

    useEffect(() => {
        checkCamera();
    }, [videoRef]);


    const captureRecording = () => {
        if (childVideoRef.current) {
            if (childVideoRef?.current?.srcObject) {
                setVideoPrams((prev) => ({
                    ...prev,
                    isRecording: true,
                }));

                const stream = childVideoRef.current.srcObject;
                const options = { mimeType: "video/webm; codecs=vp9" };
                const childRecorder = new MediaRecorder(stream, options);

                childRecorder.ondataavailable = (e) => {
                    // if (e.data.size > 0) {

                    // }

                    setVideoPrams((prev) => ({
                        ...prev,
                        recordedChunks: [
                            ...prev.recordedChunks,
                            e.data,
                        ],
                        videoDuration: prev.videoDuration + 1,
                    }))
                };

                childRecorder.onstop = async () => {
                    const duration = new Date().getTime() - startTime.current;
                    setVideoPrams((prev) => ({
                        ...prev,
                        videoDuration: parseInt(duration / 1000),
                        recordingDuration: duration,
                        isRecordingComplete: true,
                    }))
                };

                childRecorder.start(1000);
                childMediaRecorder.current = childRecorder;
                startTime.current = new Date().getTime();

                const timeOut = setTimeout(() => {
                    handleStopRecording();
                    setAlertNotification({
                        status: true,
                        type: "error",
                        message: "Recording Timeout!!",
                    })
                }, videoPrams.timeLimit * 1000);

                setVideoPrams((prev) => ({
                    ...prev,
                    timeLimitTimeout: timeOut,
                }))
            }
        }
    }

    const handleStopRecording = () => {
        if (childMediaRecorder.current) {
            clearTimeout(videoPrams?.timeLimitTimeout);
            childMediaRecorder.current.stop();
        }
    }

    const fixVideoMetadata = (rawVideoBlob) => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        if (isSafari) {
            return Promise.resolve(rawVideoBlob)
        }
        Blob.arrayBuffer ??= function () {
            return new Response(this).arrayBuffer()
        }
        return fixWebmDuration(rawVideoBlob, videoPrams?.recordingDuration, { logger: false })
    }

    const discardRecording = () => {
        clearTimeout(videoPrams?.timeLimitTimeout);

        setVideoPrams({
            isCameraOn: true,
            isRecording: false,
            videoDuration: 0,
            recordedVideo: null,
            isRecordingComplete: false,
            videoUrl: null,
            videoBlob: null,
            recordedChunks: [],
            timeLimit: videoPrams?.timeLimit, //setting timeout again
            thereWasAnError: false,
            recordingDuration: 0,
            isUploading: false,
            isUploaded: false,
            timeLimitTimeout: null,
        });
    }

    const submitRecording = async () => {
        setLoader(true);
        setVideoPrams((prev) => ({
            ...prev,
            isUploading: true,
        }));

        const blobName = "video_upload_" + new Date().getTime() + ".webm";
        const recordedFile = new File([videoPrams.videoBlob], blobName, { type: "video/webm" });

        setPauseTime(true);
        await axios.post(uploadFileRegular, {
            filename: blobName,
            file_type: "video_upload",
            file: recordedFile,
        }, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => response)
            .then((response) => {
                setLoader(false);
                setPauseTime(false);
                if (response.status === 200) {
                    handleFileUploadupdate(response.data.data.path, data.question_details.questions[count].id);
                    setVideoPrams((prev) => ({
                        ...prev,
                        isUploaded: true,
                    }));

                }
            });
    }

    useEffect(() => {
        if (videoPrams.isRecordingComplete == true) {
            const recordedBlobBuggy = new Blob(videoPrams?.recordedChunks, { type: "video/webm" });

            fixVideoMetadata(recordedBlobBuggy).then((fixedVideoBlob) => {
                setVideoPrams((prev) => ({
                    ...prev,
                    videoBlob: fixedVideoBlob,
                    videoUrl: URL.createObjectURL(fixedVideoBlob),
                    isRecording: false,
                }));
            });
        }
    }, [videoPrams.isRecordingComplete])

    useEffect(() => {
        if (videoPrams.isUploaded == true) {
            handelNextSubmit()
        }
    }, [videoPrams.isUploaded])

    return (
        <Container
            maxWidth="lg"
            style={{
                padding: "0px",
                borderRadius: "12px",
            }}
        >
            <Box
                style={{
                    backgroundColor: "white",
                    padding: "55px",
                    boxShadow:
                        "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                            Question
                        </Typography>
                        <Grid>
                            <Typography
                                variant="body1"
                                sx={{ fontSize: "18px", fontWeight: 500, mt: 2 }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: data.question_details?.questions[count].question }} />
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {videoPrams?.isConnecting && <CircularProgress sx={{ color: "var(--text-color-black)" }} />}
                        <Grid sx={{ position: "relative" }}>
                            {videoPrams?.isCameraOn === true &&
                                <>
                                    {
                                        videoPrams?.isRecording && <Grid sx={{ position: "absolute", top: 10, right: 10, width: "100%", textAlign: "end", }}>
                                            <RadioButtonCheckedIcon sx={{ color: "red" }} />
                                        </Grid>
                                    }
                                    <Grid>
                                        <video ref={childVideoRef} autoPlay muted style={{ width: "100%", maxWidth: "500px" }}></video>
                                    </Grid>
                                    <Grid sx={{ height: "25px", position: "absolute", bottom: "8px", width: "100%", textAlign: "center", backdropFilter: "brightness(0.5)", color: "white" }}>
                                        <span style={{ fontSize: "13px" }}>
                                            {formatSecondsToMinutes(videoPrams.videoDuration)} / {formatSecondsToMinutes(videoPrams?.timeLimit)}
                                        </span>
                                    </Grid>

                                </>
                            }

                            {videoPrams.thereWasAnError === true && <>
                                <Grid className="cameraSetupWarning" container spacing={0}>
                                    <Grid item sx={{ display: "flex", alignItems: "center" }} xs={1}>
                                        <ReportProblemOutlinedIcon />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: 500 }}>
                                            It seems you don't have a camera or mic connected to your computer or they are blocked. To enable the camera and mic, click on the camera blocked
                                            icon in your browser's address bar. <br/>
                                            If you don't enable a camera, you can skip the question.
                                        </Typography>
                                        <Button
                                                sx={{
                                                    boxShadow: "none",
                                                    bgcolor: "var(--button-bg-color-assessment)",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "capitalize",
                                                    mt: 2,
                                                    mb: 2,
                                                    "&:hover": {
                                                        bgcolor: "var(--button-hover-bg-color-assessment)",
                                                    },
                                                }}
                                                onClick={() => {
                                                    checkCamera()
                                                }}
                                                variant="contained"
                                            >
                                                Try Again
                                            </Button>
                                    </Grid>
                                </Grid>
                            </>}
                        </Grid>
                    </Grid>
                  
                    {alertNotification?.status ? <Alerts notification={alertNotification} setNotification={setAlertNotification} /> : ""}

                    <Grid item style={{ padding: "0px 24px" }} xs={12} md={12}>
                        <Grid sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "10px" }}>
                            {
                                videoPrams.thereWasAnError === false ? (
                                    !videoPrams.videoUrl ? (
                                        !videoPrams?.isRecording ? (
                                            <Button
                                                sx={{
                                                    boxShadow: "none",
                                                    bgcolor: "var(--button-bg-color-assessment)",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "capitalize",
                                                    mt: 2,
                                                    mb: 2,
                                                    "&:hover": {
                                                        bgcolor: "var(--button-hover-bg-color-assessment)",
                                                    },
                                                }}
                                                disabled={videoPrams?.isConnecting}
                                                onClick={() => {
                                                    captureRecording()
                                                }}
                                                variant="contained"
                                            >
                                                Start Recording
                                            </Button>
                                        ) : (
                                            <Button
                                                sx={{
                                                    boxShadow: "none",
                                                    bgcolor: "#276678",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "capitalize",
                                                    mt: 2,
                                                    mb: 2,
                                                    "&:hover": {
                                                        bgcolor: "#276678",
                                                    },
                                                }}
                                                onClick={() => {
                                                    handleStopRecording()
                                                }}
                                                variant="contained"
                                            >
                                                Stop Recording
                                            </Button>
                                        )
                                    ) : (
                                        <>
                                            <Button
                                                sx={{
                                                    boxShadow: "none",
                                                    bgcolor: "#276678",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "capitalize",
                                                    mt: 2,
                                                    mb: 2,
                                                    "&:hover": {
                                                        bgcolor: "#276678",
                                                    },
                                                }}
                                                disabled={videoPrams?.isUploading}
                                                onClick={() => {
                                                    discardRecording()
                                                }}
                                                variant="contained"
                                            >
                                                Discard Recording
                                            </Button>

                                            <LoadingButton
                                                sx={{
                                                    boxShadow: "none",
                                                    bgcolor: "var(--button-bg-color-assessment)",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "capitalize",
                                                    mt: 2,
                                                    mb: 2,
                                                    "&:hover": {
                                                        bgcolor: "var(--button-bg-color-assessment)",
                                                    },
                                                }}
                                                disabled={videoPrams?.isUploading}
                                                onClick={() => {
                                                    submitRecording()
                                                }}
                                                endIcon={<SaveIcon />}
                                                loading={videoPrams?.isUploading}
                                                loadingPosition="end"
                                                variant="contained"
                                            >
                                                <span>Submit</span>
                                            </LoadingButton>
                                        </>
                                    )
                                ) : (<>
                                </>)
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default VideoRecording;
