import { Box, Grid } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Logo from "../../../Assests/Images/LOGO.png";
import {
    GetTalentAssessmentQuestion,
    SubmitTalentAssessmentAnswer,
    SubmitTalentAssessmentAnswerNext,
    TalentAssessmentUploadChunks,
    TalentAssessmentUploadScreenshots,
    TalentAssessmentUploadComplete,
    TalentAssessmentScreenRecordingComplete,
    getUserIP
} from "../../../Constants/ConstantsUrl";
import axios from "axios";
import QualifyingType from "./QualifyingType/Index";
import OfficialType from "./OfficialType/Index";
import CustomType from "./CustomType/Index";
import * as faceapi from "face-api.js";
import { useNavigate, useLocation } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import StartTestConfirm from "./QuestionForm/StartTestConfirm";
import Loader from "../../Common/Loader";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LoaderWithInfo from "../../Common/LoaderWithInfo";
import { getOSName } from '../../../utils/helper';
import {TransitionsModal} from '../CameraSetup/Index';

const formatTime = (timeInSeconds) => {
    let minutes = Math.floor(timeInSeconds / 60);
    let seconds = Math.floor(timeInSeconds % 60);

    if (isNaN(minutes)) {
        minutes = 0;
    }

    if (isNaN(seconds)) {
        seconds = 0;
    }

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

let finalResuldtId = 0;

function Index() {
    const location = useLocation();
    const oldData = location.state?.data;
    const extraTimeData = location.state?.time;
    const fullScreenHandler = useFullScreenHandle();
    const [fullScreenClosedCount, setFullScreenClosedCount] = useState(0);
    const [ipAddress, setIpAddress] = useState("");
    const [ipAddressList, setIpAddressList] = useState([]);

    const mediaRecorder = useRef(null);
    const ScreenRecorder = useRef(null);
    const videoRef = useRef(null);
    const screenRef = useRef(null);
    const screenCanvasRef = useRef(null);
    const camCanvasRef = useRef(null);

    const [screenScreenshots, setScreenScreenShots] = useState([]);
    const [camScreenshots, setCamScreenShots] = useState([]);

    const startTime = useRef(null);
    const [errorLogs, setErrorLogs] = useState([]);
    const [ipResction, setIpResction] = useState(true);
    const [tabSwitch, setTabSwitch] = useState(true);
    const [faceDetection, setFaceDetection] = useState(true);

    const [copyPasteEnable, setCopyPasteEnable] = useState(true);
    const [mouseMove, setMouseMove] = useState(true);
    const [loaderVar, setLoader] = useState(false);
    const [finalSubmitLoader, setFinalSubmitLoader] = useState(false);
    const [goToStart, setGoToStart] = useState(false);
    const [checkScreenRecording, setCheckScreenRecording] = useState(true);
    const [checkWebCumRecording, setCheckWebCumRecording] = useState(true);

    const [saveScreenRecording, setSaveScreenRecording] = useState(false);
    const [saveWebCamRecording, setSaveWebCamRecording] = useState(false);

    const [typeSteps, setTypeSteps] = useState(1);
    const [quilifing, setQualifying] = useState([]);
    const [official, setOfficial] = useState([]);
    const [totalOfficialQuestion, setTotalOfficialQuestion] = useState(0);
    const [officialSteps, setOfficialSteps] = useState(0);
    const [officialQuestionBunchCount, setOfficialQuestionBunchCount] = useState(0);
    const [customQue, setCustomQue] = useState([]);
    const [totalCustomQuestion, setTotalCustomQuestion] = useState(0);
    const [customQueSteps, setCustomSteps] = useState(0);
    const [customQueQuestionBunchCount, setCustomQuestionBunchCount] = useState(0);
    const [resultId, setResultId] = useState(null);
    const [candidateName,setCandidateName]=useState(oldData.first_name);
    const [open, setOpen] = React.useState(false);
    const [mandatoryScreenErr,setMandatoryScreenErr]=useState(false);
    const [antiCheatingData,setAntiCheatingData]=useState([]);
    const userOS = getOSName();

    const goToFullScreen = () => {
        screenChangefunc();
        setGoToStart(true);
        fullScreenHandler.enter().catch(() => false);
    };

    const navigate = useNavigate();

    // Add Answer

    // Disable Copy Paste
    const copyPaste = (e) => {
        if (copyPasteEnable) {
            e.preventDefault();
            //alert("copy paste not allowed");
            return false;
        }
    };

    // Full Screen Handler
    const handleFullScreenChange = () => {
        if (!document.fullscreenElement) {
            setFullScreenClosedCount((prevCount) => prevCount + 1);
        }
    };

    const onNextHandleFullScreen = () => {
        if (!document.fullscreenElement) {
            fullScreenHandler.enter().catch(() => false);
        }
    };

    // Fetch Assessment Question
    const fetchQuestions = async () => {
        setLoader(true);
        try {
            const response = await axios.get(GetTalentAssessmentQuestion + oldData.token);
            const res = response.data;
            if (res.status) {
                startTime.current = new Date();
                setIpResction(res.data.proctor.ipResction);
                setTabSwitch(res.data.proctor.tabSwitch);
                setFaceDetection(res.data.proctor.faceDetection);
                setAntiCheatingData(res.data.is_anti_cheating_mode);
                if (res.data.is_anti_cheating_mode.is_snapshot_enable) {
                    startRecording();
                    // setVideoRecording(res.data.proctor.videoRecording);
                }else{
                    setCheckWebCumRecording(false);
                }
                if (res.data.is_anti_cheating_mode.is_screenshot_enable) {
                    startScreenRecording();
                    // setScreenRecording(res.data.proctor.screenRecording);
                }else{
                    setCheckScreenRecording(false);
                }
                setCopyPasteEnable(res.data.proctor.copyPaste);
                setMouseMove(res.data.proctor.mouseMove);
                // Qualify Question
                const totalQualify = Object.keys(res.data.qualifying_question).length;
                if (totalQualify > 0) {
                    setTypeSteps(0);
                }
                // setTotalQualifyQuestion(totalQualify);
                setQualifying(res.data.qualifying_question);

                // Official Question
                const totalOfficial = Object.keys(res.data.official_question).length;
                if (totalQualify === 0 && totalOfficial > 0) {
                    setTypeSteps(1);
                }
                setTotalOfficialQuestion(totalOfficial);
                setOfficial(res.data.official_question);


                // Custom Question
                const totalcustom = Object.keys(res.data.custom_question).length;
                if (totalQualify === 0 && totalOfficial === 0 && totalcustom > 0) {
                    setTypeSteps(2);
                }
                setTotalCustomQuestion(totalcustom);
                setCustomQue(Object.values(res.data.custom_question));
                setLoader(false);
            }
        } catch (error) {
            if (error.response.status === 403) {
                navigate("/assessment-submitted", { state: { data: oldData } });
            }
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchQuestions();
        getIPandTimeOut();
        faceAPIFunc();
        // leaveSiteAlert();
        rightClickDisable();
        fullScreenChangeFun();
    }, []);

    const getIPandTimeOut = async () => {
        try {
            await axios.get(getUserIP)
                .then((data) => {
                    data = data.data;
                    if (ipAddress !== data.ip && ipResction) {
                        setIpAddress(data.ip);
                        setIpAddressList((prevData) => ([
                            ...prevData,
                            data.ip,
                        ]));
                    } else {
                        setIpAddress(data.ip);
                        setIpAddressList((prevData) => ([
                            ...prevData,
                            data.ip,
                        ]));
                    }
                });
        } catch (e) {
            fetch("https://checkip.amazonaws.com/").then(res => res.text()).then(data => {
                if (ipAddress !== data && ipResction) {
                    setIpAddress(data);
                    setIpAddressList((prevData) => ([
                        ...prevData,
                        data,
                    ]));
                } else {
                    setIpAddress(data);
                    setIpAddressList((prevData) => ([
                        ...prevData,
                        data,
                    ]));
                }
            })
        }

    };

    const leaveSiteAlert = () => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // This is needed for Chrome
            alert("Changes you made may not be saved.")
        };

        const handleBackButton = (event) => {
            event.preventDefault();
            alert("Changes you made may not be saved.")
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handleBackButton);
        };
    }

    useEffect(() => {
        ; (async () => {
            if (saveScreenRecording == true) {
                let fileName = oldData.invitation_id + "_cam_screen_Upload.mp4";
                let fileType = "screen_rec_path";
                if (screenScreenshots.length > 0) {
                    await uploadScreenShots(screenScreenshots, fileType, fileName);
                }
                await uploadScreenRecordingComplete();
            }
        })();
    }, [saveScreenRecording]);

    useEffect(() => {
        ; (async () => {
            if (saveWebCamRecording == true) {
                let fileName = oldData.invitation_id + "_cam_video_Upload.mp4";
                let fileType = "video_rec_path";
                if (camScreenshots.length > 0) {
                    await uploadScreenShots(camScreenshots, fileType, fileName);
                }
                await uploadCamRecordingComplete();
            }
        })();
    }, [saveWebCamRecording]);

    const rightClickDisable = () => {
        const handleContextmenu = (e) => {
            e.preventDefault();
        };
        // Disable Right Click
        document.addEventListener("contextmenu", handleContextmenu);
        return function cleanup() {
            document.removeEventListener("contextmenu", handleContextmenu);
        };
    };
    const screenChangefunc = () => {
        document.addEventListener("mouseleave", handleWindowBlur);
        document.addEventListener("mouseenter", handleWindowFocus);
        window.addEventListener("visibilitychange", () => handleWindowChange());

        return () => {
            document.removeEventListener("mouseleave", handleWindowBlur);
            document.removeEventListener("mouseenter", handleWindowFocus);
            window.removeEventListener("visibilitychange", handleWindowChange);
        };
    }
    const faceAPIFunc = () => {
        const loadModels = async () => {
            await Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
                faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
                faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
            ]);
        };
        loadModels();
    };

    const fullScreenChangeFun = () => {
        document.addEventListener("fullscreenchange", handleFullScreenChange);
        return () => {
            document.removeEventListener("fullscreenchange", handleFullScreenChange);
        };
    };

    const handleWindowChange = () => {
        if (tabSwitch) {
            const b = new Date();
            if (startTime.current !== null) {
                var tabtimestamp = (b.getTime() - startTime.current.getTime()) / 1000;
                if (document.visibilityState === "visible") {
                    logError("Tab is active", tabtimestamp);
                } else {
                    logError("Tab is inactive", tabtimestamp);
                }
            }
        }
    };

    const handleWindowBlur = () => {
        if (mouseMove) {
            const cursortimestamp = (new Date() - startTime.current) / 1000;
            logError("Cursor left window", cursortimestamp);
        }
    };

    const handleWindowFocus = () => {
        if (mouseMove) {
            const cursortimestamp = (new Date() - startTime.current) / 1000;
            logError("Cursor left off", cursortimestamp);
        }
    };

    const captureScreenScreenshot = () => {
        const video = screenRef.current;
        const canvas = screenCanvasRef.current;
        if (video && canvas && video.srcObject) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            // const imageData = canvas.toDataURL('image/jpeg');
            mouseMovementsRef.current.forEach((movement, index) => {
                if (index > 0) {
                    const prevMovement = mouseMovementsRef.current[index - 1];
                    context.beginPath();
                    context.moveTo(prevMovement.clientX, prevMovement.clientY);
                    context.lineTo(movement.clientX, movement.clientY);
                    context.strokeStyle = 'red';
                    context.lineWidth = 2;
                    context.stroke();
                    context.closePath();
                }
            });

            mouseMovementsRef.current = [];
            canvas.toBlob(async function (blob) {
                if (blob) {
                    const file = new File([blob], new Date().getTime(), { type: 'image/jpeg' });
                    setScreenScreenShots(prev => {
                        let screen = [...prev, file];
                        if (screen.length == 10) {
                            let fileName = oldData.invitation_id + "_cam_screen_Upload.mp4";
                            let fileType = "screen_rec_path";
                            uploadScreenShots(screen, fileType, fileName);
                            screen = [];
                        }
                        return screen;
                    });
                } else {
                    console.error('Failed to create blob from canvas data');
                }
            }, 'image/jpeg');
        }
    }

    const uploadScreenShots = async (screenshots, fileType, fileName) => {
        const formData = new FormData();
        screenshots.forEach((screenshot, index) => {
            formData.append(`screenshot[]`, screenshot);
        });
        formData.append("filename", fileName);
        formData.append("file_type", fileType);
        // formData.append("filename", oldData.invitation_id + "_cam_screen_Upload.mp4");
        // formData.append("file_type", "screen_rec_path");
        try {
            await axios.post(TalentAssessmentUploadScreenshots, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            // Update your progress bar here if needed
        } catch (error) {
            console.error("Error uploading chunk:", error);
            // Handle errors as needed
        }
    }

    const startScreenRecording = async () => {
        try {
            if (
                navigator.mediaDevices &&
                navigator.mediaDevices.getDisplayMedia &&
                window.MediaRecorder
            ) {
                // let count = 0;
                // let intervalIdScreen;
                const screenStream = await navigator.mediaDevices.getDisplayMedia({
                    video: { mediaSource: "screen", cursor: "always" },
                    audio: true,
                    systemAudio: "include",
                });
                // let displaySurface = screenStream.getVideoTracks()[0].getSettings().displaySurface;
                // if (displaySurface !== 'monitor') {
                //     console.log('Selection of entire screen mandatory!');
                // }
                screenRef.current.srcObject = screenStream;
                screenRef.current.style.display = "none";
                screenRef.current.play();

                const options = { mimeType: "video/webm; codecs=vp9" };
                const recorder = new MediaRecorder(screenStream, options);

                recorder.ondataavailable = async (e) => {
                    captureScreenScreenshot();
                };

                recorder.onstop = async () => {
                    if (screenRef.current) {
                        // Get srcObject from the video element
                        const srcObject = screenRef.current.srcObject;

                        if (srcObject) {
                            // Get tracks from the srcObject
                            const tracks = srcObject.getTracks();

                            // Stop each track
                            tracks.forEach((track) => track.stop());
                        }
                    }
                    setSaveScreenRecording(true);
                    document.removeEventListener('mousemove', recordMouseMovement);
                };

                recorder.start(1000);
                ScreenRecorder.current = recorder;
                startTime.current = new Date();
                

                document.addEventListener('mousemove', recordMouseMovement);
            } else {
                setCheckScreenRecording(false);
                console.error("MediaRecorder or getDisplayMedia is not supported in this browser.");
            }
        } catch (error) {
            setCheckScreenRecording(false);
            console.error("Error accessing screen media:", error);
        }
    };

    const startTimeRef = useRef(null);
    const mouseMovementsRef = useRef([]);

    const recordMouseMovement = (event) => {
        const { clientX, clientY } = event;
        const timestamp = new Date() - startTimeRef.current;

        mouseMovementsRef.current.push({ clientX, clientY, timestamp });
    };

    useEffect(() => {
        if (antiCheatingData.is_mandatory_screenrec) {
            setMandatoryScreenErr(true);
            setOpen(true);
        }
    },[checkScreenRecording])

    const stopScreenRecording = async () => {
        setLoader(true);
        if (ScreenRecorder.current) {
            try {
                await ScreenRecorder.current.stop();
                // setIsScreenRecording(false);
                const recordedDuration = (new Date() - startTime.current) / 1000;
                logError("Screen end", recordedDuration); // in seconds
            } catch (error) {
                console.error("Error stopping recording:", error);
            }
        } else {
            // console.log("calling stopScreenRecording else function")
            if (oldData.intro_outro_video_path?.outro_video_path !== '' && oldData.intro_outro_video_path?.outro_video_path !== null) {
                navigate("/outro-video", { state: { data: oldData } });
            } else {
                navigate("/feedback", { state: { data: oldData } });
            }
        }
    };

    const captureCamScreenshot = () => {
        const video = videoRef.current;
        const canvas = camCanvasRef.current;
        if (video && canvas && video.srcObject) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            // const imageData = canvas.toDataURL('image/jpeg');

            canvas.toBlob(async function (blob) {
                if (blob) {
                    const file = new File([blob], new Date().getTime(), { type: 'image/jpeg' });
                    setCamScreenShots(prev => {
                        let screen = [...prev, file];
                        if (screen.length == 10) {
                            let fileName = oldData.invitation_id + "_cam_video_Upload.mp4";
                            let fileType = "video_rec_path";
                            uploadScreenShots(screen, fileType, fileName);
                            screen = [];
                        }
                        return screen;
                    });
                } else {
                    console.error('Failed to create blob from canvas data');
                }
            }, 'image/jpeg');
        }
    }
    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: {
                        exact: oldData.cameraDeviceId,
                    },
                },
                audio: true,
            });
            videoRef.current.srcObject = stream;
            videoRef.current.style.display = "none";
            videoRef.current.play();

            var options = { mimeType: "video/webm; codecs=vp9" };
            const recorder = new MediaRecorder(stream, options);

            recorder.ondataavailable = async (e) => {
                captureCamScreenshot();
            };

            recorder.onstop = async () => {
                if (videoRef.current) {
                    // Get srcObject from the video element
                    const srcObject = videoRef.current.srcObject;

                    if (srcObject) {
                        // Get tracks from the srcObject
                        const tracks = srcObject.getTracks();

                        // Stop each track
                        tracks.forEach((track) => track.stop());
                    }
                }
                setSaveWebCamRecording(true);
                // await uploadCamRecordingComplete();
            };

            recorder.start(1000);
            mediaRecorder.current = recorder;

            //Face detection logic
            videoRef.current.addEventListener("loadedmetadata", () => {
                startFaceDetection(videoRef.current);
            });
            startFaceDetection(videoRef.current);
        } catch (error) {
            setCheckWebCumRecording(false);
            console.error("Error accessing webcam and/or microphone:", error);
        }
    };

    const startFaceDetection = async (video) => {
        try {
            setInterval(async () => {
            const detections = await faceapi
                .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
                .withFaceLandmarks()
                .withFaceDescriptors();
            if (detections.length >= 2 && faceDetection) {
                logError(
                    "Multiple faces detected " + detections.length,
                    (new Date() - startTime.current) / 1000
                );
            } else if (detections.length === 0 && faceDetection) {
                logError("No faces detected", (new Date() - startTime.current) / 1000);
            }
            }, 100);
        } catch (error) {
            console.error("Error in face detection:", error);
        }
    };

    const stopRecording = async () => {
        setLoader(true);
        if (mediaRecorder.current) {             
            mediaRecorder.current.stop();
            if (videoRef.current) {
                // Get srcObject from the video element
                const srcObject = videoRef.current.srcObject;
                if (srcObject) {
                    // Get tracks from the srcObject
                    const tracks = srcObject.getTracks();
                    // Stop each track
                    tracks.forEach((track) => track.stop());
                }
            }
            const recordedDuration = (new Date() - startTime.current) / 1000;
            console.log("Video end", recordedDuration); // in seconds
        } else {
            if (oldData.intro_outro_video_path?.outro_video_path !== '' && oldData.intro_outro_video_path?.outro_video_path !== null) {
                navigate("/outro-video", { state: { data: oldData } });
            } else {
                navigate("/feedback", { state: { data: oldData } });
            }
        }
    };

    const uploadScreenRecordingComplete = async () => {
        setFinalSubmitLoader(true);
        await axios.post(TalentAssessmentScreenRecordingComplete, {
            filename: oldData.invitation_id + "_cam_screen_Upload.mp4",
            file_type: "screen_rec_path",
            invitation_id: oldData.invitation_id,
            assessment_id: oldData.assessment_id,
            candidate_id: oldData.candidate_id,
            result_id: finalResuldtId,
        }).then((response) => response)
            .then((response) => {
                setFinalSubmitLoader(false);
                if (checkWebCumRecording) {
                    stopRecording();
                } else {
                    if (oldData.intro_outro_video_path?.outro_video_path !== '' && oldData.intro_outro_video_path?.outro_video_path !== null) {
                        navigate("/outro-video", { state: { data: oldData } });
                    } else {
                        navigate("/feedback", { state: { data: oldData } });
                    }
                }
            });
    }

    const uploadCamRecordingComplete = async () => {
        await axios.post(TalentAssessmentScreenRecordingComplete, {
            filename: oldData.invitation_id + "_cam_video_Upload.mp4",
            file_type: "video_rec_path",
            invitation_id: oldData.invitation_id,
            assessment_id: oldData.assessment_id,
            candidate_id: oldData.candidate_id,
            result_id: finalResuldtId,
        }).then((response) => response)
            .then((response) => {
                setLoader(false);
                if (oldData.intro_outro_video_path?.outro_video_path !== '' && oldData.intro_outro_video_path?.outro_video_path !== null) {
                    navigate("/outro-video", { state: { data: oldData } });
                } else {
                    navigate("/feedback", { state: { data: oldData } });
                }
            });
    }

    const logError = (errorType, timestamp) => {
        const errorLog = { errorType, timestamp }; // Create log object
        setErrorLogs((prevLogs) => [...prevLogs, errorLog]);
    };

    let checkCalling = true;
    const handelFinalSubmit = async (currentStep, resultAnswer, questions, notSaved) => {
        setLoader(true);
        if (currentStep === 0) {
            const sendArr = {
                parent_question_id: questions.question_id,
                question_type_id: questions.question_type_id,
                question_category_id: questions.question_category_id,
                result_sub_meta: {
                    ...resultAnswer
                },
            }

            let response = await submitBatchResponse(sendArr);
            if (totalOfficialQuestion > 0) {
                setTypeSteps(1);
            } else if (totalCustomQuestion >0) {
                setTypeSteps(2);
            }
            else{
                finalResuldtId = resultId;
                uploadFinalVideos(resultId);
            }
        } else if (currentStep === 1) {
            const officialQuestionBunchCountNew = officialQuestionBunchCount + 1;
            const sendArr = {
                parent_question_id: questions.question_id,
                question_type_id: questions.question_type_id,
                question_category_id: questions.question_category_id,
                result_sub_meta: {
                    ...resultAnswer
                },
            }
            // api call
            // if (!notSaved) {
                await submitBatchResponse(sendArr);
            // }
            
            if (totalOfficialQuestion > officialQuestionBunchCountNew) {
                setOfficialQuestionBunchCount(officialQuestionBunchCountNew);
                setOfficialSteps((prev) => prev + 1)
            } else {
                if (totalCustomQuestion > 0) {
                    setTypeSteps(2);
                } else {
                    finalResuldtId = resultId;
                    uploadFinalVideos(resultId);
                }
            }
            // bunch api call
        } else if (currentStep === 2) {
            const sendArr = {
                parent_question_id: questions.question_id,
                question_type_id: questions.question_type_id,
                question_category_id: questions.question_category_id,
                result_sub_meta: {
                    ...resultAnswer
                },
            }
            // api call
            if (!notSaved) {                 
                let response = await submitBatchResponse(sendArr);
                const customQueQuestionBunchCountNew = customQueQuestionBunchCount + 1;
                if (totalCustomQuestion > customQueQuestionBunchCountNew) {
                    setCustomQuestionBunchCount(customQueQuestionBunchCountNew);
                    setCustomSteps((prev) => prev + 1);
                } else {
                    uploadFinalVideos(response.result_id)
                    setTypeSteps(3);
                }
            } else {
                await submitBatchResponse(sendArr);
                const customQueQuestionBunchCountNew = customQueQuestionBunchCount + 1;
                if (totalCustomQuestion > customQueQuestionBunchCountNew) {
                    if (checkCalling) {
                        checkCalling = false;
                        setCustomQuestionBunchCount(customQueQuestionBunchCountNew);
                        setCustomSteps((prev) => prev + 1);
                    }
                } else {
                    setTypeSteps(3);
                    callStepThird();
                }
            }
        } else {
            callStepThird();
        }
        setLoader(false);
    };

    const callStepThird = () => {
        if (oldData.intro_outro_video_path?.outro_video_path !== '' && oldData.intro_outro_video_path?.outro_video_path !== null) {
            navigate("/outro-video", { state: { data: oldData } });
        } else {
            navigate("/feedback", { state: { data: oldData } });
        }
    }

    const submitResponseOnEveryNext = async (answerSubmitOnNext) => {
        // let logs = [...errorLogs, { errorType: "videoEnd", timestamp: attemptTime }, { errorType: "screenEnd", timestamp: attemptTime }];

        const data = {
            answer: answerSubmitOnNext,
            extra_time: extraTimeData,
            // cheatingLogs: logs,
            // ipAddressList: ipAddressList,
            // fullScreenClosedCount: fullScreenClosedCount,
            platform: userOS,
            browser: navigator.userAgent,
            invitation_token: oldData.token,
            location: oldData.locationDis,
        };

        await axios.post(SubmitTalentAssessmentAnswerNext, data);
    }

    const submitBatchResponse = async (sendArr) => {
        let attemptTime = 0;
        const b = new Date();
        if (startTime.current !== null) {
            attemptTime = (b.getTime() - startTime.current.getTime()) / 1000;
        }

        let logs = [...errorLogs, { errorType: "videoEnd", timestamp: attemptTime }, { errorType: "screenEnd", timestamp: attemptTime }];

        let returnData = '';
        const allData = {
            // answer: [sendArr],
            cheatingLogs: logs,
            ipAddressList: ipAddressList,
            fullScreenClosedCount: fullScreenClosedCount,
            platform: userOS,
            browser: navigator.userAgent,
            invitation_token: oldData.token,
            location: oldData.locationDis,
            // result_id: resultId,
            extra_time: extraTimeData
        };
        await axios.post(SubmitTalentAssessmentAnswer, allData)
            .then((response) => response)
            .then((response) => {
                if (response.data.status === 200) {
                    returnData = response.data.data;
                    setResultId(response.data.data.result_id);
                    finalResuldtId = response.data.data.result_id;
                }
            })
        return returnData;
    }

    const uploadFinalVideos = (result_id) => {
        setLoader(true);
        if (checkScreenRecording) {
            stopScreenRecording(result_id);
        } else if (checkWebCumRecording) {
            stopRecording(result_id);
        } else {
            if (oldData.intro_outro_video_path?.outro_video_path !== '' && oldData.intro_outro_video_path?.outro_video_path !== null) {
                navigate("/outro-video", { state: { data: oldData } });
            } else {
                navigate("/feedback", { state: { data: oldData } });
            }
            //window.location.reload();
        }
        //setLoader(false);
    }

    return (
        <FullScreen handle={fullScreenHandler}>
            {finalSubmitLoader && <LoaderWithInfo />}
            {loaderVar ? (
                <>
                    <Loader />
                </>
            ) : (<>
                <HelmetProvider>
                    <Helmet>
                        <title>Assessment - TestnHire</title>
                    </Helmet>
                </HelmetProvider>
                <Box onPaste={copyPaste} onCopy={copyPaste}>
                    <Box
                        sx={{
                            // display:"flex",
                            justifyContent: "center",
                            alignItems: "center",
                            bgcolor: "#f6f6f6",
                            minHeight: "100vh",
                            position: "relative",
                        }}
                    >
                        {goToStart ? (
                            <>
                                {typeSteps === 0 ? (
                                    <QualifyingType
                                        quilifing={quilifing}
                                        handelFinalSubmit={handelFinalSubmit}
                                        typeStep={typeSteps}
                                        oldData={oldData}
                                        bunchQuestionTime={10}
                                        formatTime={formatTime}
                                        onNextHandleFullScreen={onNextHandleFullScreen}
                                        candidateName={candidateName}
                                        submitResponseOnEveryNext={submitResponseOnEveryNext}
                                    />
                                ) : (
                                    <>
                                        {typeSteps === 1 ? (
                                            <OfficialType
                                                questions={official[officialSteps]}
                                                count={officialSteps}
                                                handelFinalSubmit={handelFinalSubmit}
                                                typeStep={typeSteps}
                                                oldData={oldData}
                                                data={official}
                                                bunchQuestionTime={official[officialSteps]?.time}
                                                formatTime={formatTime}
                                                onNextHandleFullScreen={onNextHandleFullScreen}
                                                extraTimeData={extraTimeData}
                                                submitResponseOnEveryNext={submitResponseOnEveryNext}
                                            />
                                        ) : (
                                            <>
                                                {typeSteps === 2 ? (
                                                    <CustomType
                                                        questions={customQue[customQueSteps]}
                                                        count={customQueSteps}
                                                        handelFinalSubmit={handelFinalSubmit}
                                                        typeStep={typeSteps}
                                                        oldData={oldData}
                                                        data={customQue}
                                                        bunchQuestionTime={customQue[customQueSteps]?.time}
                                                        formatTime={formatTime}
                                                        onNextHandleFullScreen={onNextHandleFullScreen}
                                                        extraTimeData={0}
                                                        submitResponseOnEveryNext={submitResponseOnEveryNext}
                                                        videoRef={videoRef}
                                                    />
                                                ) : (<>
                                                    {() => handelFinalSubmit(3)}
                                                </>)}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <StartTestConfirm goToFullScreen={goToFullScreen} open={open} setOpen={setOpen} mandatoryScreenErr={mandatoryScreenErr} />
                        )}
                        <img
                            style={{
                                position: "fixed",
                                bottom: "10px",
                                left: "50%",
                                transform: "translateX(-50%)",
                                opacity: 0.35,
                            }}
                            src={Logo}
                            alt="../../../../Assests/Images/FirstSectionImg.jpg"
                        />
                    </Box>
                </Box>
            </>
            )}
            <Grid sx={{ display: "none" }}>
                <video ref={videoRef} autoPlay></video>
                <video
                    ref={screenRef}
                    autoPlay
                    muted
                    style={{ display: "none" }}
                ></video>
                <canvas ref={screenCanvasRef} style={{ display: 'none' }} />
                <canvas ref={camCanvasRef} style={{ display: 'none' }} />
            </Grid>
        </FullScreen>
    );
}

export default Index;
