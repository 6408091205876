import { Box, Container, FormControlLabel, Grid, RadioGroup, Typography } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));

function MCQ(props) {
    const { data, count, handleRadioChange, selectedValue } = props;
    const [selectButton, setselectButton] = useState("");
    const handleButtonChange = (e, count) => {
        if (selectButton === e.target.value) {
            handleRadioChange(e, count);
            setselectButton("");
        } else {
            handleRadioChange(e, count);
            setselectButton(e.target.value);
        }
    };
    return (
        <Box sx={{ minHeight: "90vh", display: "flex", alignItems: "center" }}>
            <Container
                maxWidth="lg"
                style={{
                    backgroundColor: "white",
                    padding: "0px",
                    borderRadius: "12px",
                }}
            >
                <Box
                    style={{
                        padding: "55px",
                        boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item sx={{ px: 5 }} xs={12} md={6}>
                            <Item>
                                <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                                    Question
                                </Typography>

                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    <div dangerouslySetInnerHTML={{ __html: data?.question_details?.questions[count]?.question }} />
                                </Typography>
                            </Item>
                        </Grid>
                        <Grid item sx={{ px: 5 }} xs={12} md={6}>
                            <Item>
                                <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                                    Select only one
                                </Typography>
<Grid sx={{maxHeight:"60vh", overflowY:"scroll"}}>
                                <RadioGroup
                                    style={{ flexDirection: "column" }}
                                    row
                                    aria-label="radio-group-english"
                                    name="fluentEnglish"
                                    //onChange={(e)=>handleButtonChange(e, data?.question_details?.questions[count])}
                                >
                                    {data?.question_details?.questions[count]?.options.map((item, keys) => (
                                        <Grid
                                            key={keys}
                                            className={selectButton == parseInt(item.id) ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}
                                            sx={{
                                                my: 1,
                                            }}
                                            onChange={(e) => handleButtonChange(e, data?.question_details?.questions[count])}
                                        >
                                            <FormControlLabel
                                                sx={{ width: "100%", boxShadow: "0 2px 8px 1px #a8a8a833" }}
                                                value={item.id}
                                                control={
                                                    <Checkbox
                                                        inputProps={{ "aria-label": "controlled" }}
                                                        icon={<RadioButtonUncheckedIcon sx={{fontSize:"35px", color:"#ededed"}}/>}
                                                        checkedIcon={<RadioButtonCheckedIcon sx={{fontSize:"35px", color:"white"}}/>}
                                                        checked={parseInt(item.id) === parseInt(selectButton) ? true : false}
                                                    ></Checkbox>
                                                }
                                                // checked={tr}
                                                label={
                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: item.options }} />
                                                    </span>
                                                }
                                            />
                                        </Grid>
                                    ))}
                                </RadioGroup>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default MCQ;
