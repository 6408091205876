import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import MCQSingle from "../QuestionForm/MCQSingle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Logo from "../../../../Assests/Images/LOGO.png";
import LinearProgress from "@mui/material/LinearProgress";
import ParentComponent from "../QuestionForm/ParentComponent";
import MCQMultiple from "../QuestionForm/MCQMultiple";
import Essay from "../QuestionForm/Essay";
import FileUpload from "../QuestionForm/FileUpload";
import VideoRecording from "../QuestionForm/VideoRecording";
import Code from "../QuestionForm/Code";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../Common/Loader";
import TimerPage from "./TimerPage";

const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

const Index = React.memo((props) => {
    const { data, questions, count, handelFinalSubmit, typeStep, bunchQuestionTime, formatTime, onNextHandleFullScreen, oldData, extraTimeData, submitResponseOnEveryNext } = props;
    const [questionsLength, setQuestionsLength] = useState(0);
    const [skipComponent, setSkipComponent] = useState(true);
    const [progressQuestion, setProgressQuestion] = useState(0);
    const [attemptQuestion, setAttemptQuestion] = useState(0);
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedValueMultiple, setSelectedValueMultiple] = useState([]);
    const [resultAnswer, setResultAnswer] = useState([]);
    const [loderval, setLoader] = useState(false);
    const [pLoader, setPLoader] = useState(true);
    const [codeSubmitted, setCodeSubmitted] = useState(false);
    const [questionTime, setQuestionTime] = useState(10);
    const [questionLocalTime, setQuestionLocalTime] = useState((bunchQuestionTime * 60 + (extraTimeData * bunchQuestionTime * 60 / 100)));
    const [time, setTime] = useState(10); // initial time in seconds
    const [pauseTime, setPauseTime] = useState(false);
    const [showWarning, setShowWarning] = useState({ display: "none" });
    const startQuestionTime = useRef(null);
    const [getNewTimer, setNewTimer] = useState(0);
    const [timeOut, setTimeOut] = useState(false);
    const handleClose = () => {
        setShowWarning({ display: "none" });
    };
    const handleOpen = () => {
        setShowWarning({ display: "block" });
    };

    const handleSkipQuestion = () => {
        const answerArr = {
            question_id: questions?.question_details.questions[attemptQuestion].id,
            answer_id: null,
            result: 0,
            completeTime: 0,
            skip: 1
        };
        setResultAnswer({
            ...resultAnswer,
            [questions?.question_details.questions[attemptQuestion].id]: answerArr,
        });

        handleClose();
        handelNextSubmit(1);
    };

    const handleNext = () => {
        onNextHandleFullScreen();
        setQuestionTime(bunchQuestionTime * 60 + (extraTimeData * bunchQuestionTime * 60 / 100));
        setQuestionLocalTime(bunchQuestionTime * 60 + (extraTimeData * bunchQuestionTime * 60 / 100));
        setTime(bunchQuestionTime * 60 + (extraTimeData * bunchQuestionTime * 60 / 100));
        startQuestionTime.current = new Date();
        setSkipComponent(false);
        setResultAnswer([]);
    };

    const handleCodeChange = (question_id, answer_id, result) => {
        const answerArr = { question_id: question_id, answer_id: answer_id, result: result };
        setResultAnswer({ ...resultAnswer, [question_id]: answerArr });
        setCodeSubmitted(true);
    };

    useEffect(() => {
        if (codeSubmitted === true) {
            handelNextSubmit();
        }
    }, [codeSubmitted])

    const handleRadioChange = (event, id) => {
        if (selectedValue === event.target.value) {
            const q_id = id.id;
            const { [q_id]: omit, ...newResultAnswer } = resultAnswer;
            setResultAnswer(newResultAnswer);
            setSelectedValue("");
        }
        else {
            const values = event.target.value;
            const answer = atob(id.answer);
            const q_id = id.id;
            let resultGet = 0;
            if (answer === values) {
                resultGet = 1;
            }
            const answerArr = { question_id: q_id, answer_id: values, result: resultGet, completeTime: 0 };
            setResultAnswer({ ...resultAnswer, [q_id]: answerArr });
            setSelectedValue(values);
        }
    };

    const handleAnswerChange = (content, id) => {
        const answerArr = { question_id: id, answer_id: content, result: null, completeTime: 0 };
        setResultAnswer({ ...resultAnswer, [id]: answerArr });
    };

    const handleRadioChangeMultiple = (event, ansDAta) => {
        const answerResult = atob(ansDAta.answer).split(",");
        const answerCount = answerResult.length;
        const point = 1 / answerCount;
        let updatedSelectedValue;
        if (selectedValueMultiple.includes(parseInt(event.target.value))) {
            updatedSelectedValue = selectedValueMultiple.filter((value) => value !== parseInt(event.target.value));
        } else {
            updatedSelectedValue = [...selectedValueMultiple, parseInt(event.target.value)];
        }
        setSelectedValueMultiple(updatedSelectedValue);
        let result = 0;
        updatedSelectedValue.forEach((selectedAnswer) => {
            if (answerResult.includes(selectedAnswer)) {
                result = result + point;
            }
            // else {
            //     result = result - point;
            // }
        });
        const answerArr = {
            question_id: ansDAta.id,
            answer_id: updatedSelectedValue?.toString(),
            result: result < 0 ? 0 : result,
            completeTime: 0
        };
        setResultAnswer({ ...resultAnswer, [ansDAta.id]: answerArr });
    };

    const handleFileUploadupdate = (file, question) => {
        const answerArr = { question_id: question, answer_id: file, result: null, completeTime: 0 };
        setResultAnswer({ ...resultAnswer, [question]: answerArr });
    };

    const handelNextSubmit = (skip) => {
        setCodeSubmitted(false);
        onNextHandleFullScreen();
        // const b = new Date();        
        const nextQueCount = attemptQuestion + 1;
        if (Object.keys(resultAnswer).length === nextQueCount || skip === 1) {
            let tabtimestamp = 0;
            if (startQuestionTime.current !== null) {
                //tabtimestamp = (b.getTime() - startQuestionTime.current.getTime()) / 1000; 
                tabtimestamp = (questionLocalTime - getNewTimer);
                setQuestionLocalTime(getNewTimer)
            }
            startQuestionTime.current = new Date();

            if (questionsLength > nextQueCount) {
                let old_answer = resultAnswer[questions?.question_details.questions[attemptQuestion].id];
                if (typeof old_answer === "undefined") {
                    old_answer = {
                        question_id: questions?.question_details.questions[attemptQuestion].id,
                        answer_id: null,
                        result: 0,
                    };
                }
                if (skip !== 1) {
                    let ansDataN = {
                        ...resultAnswer,
                        [questions?.question_details.questions[attemptQuestion].id]: {
                            ...old_answer,
                            completeTime: tabtimestamp
                        }
                    };

                    let answerSubmitOnNext = {
                        ...old_answer,
                        completeTime: tabtimestamp,
                        parent_question_id: questions.question_id,
                        question_type_id: questions.question_type_id,
                        question_category_id: questions.question_category_id,
                    }
                    submitResponseOnEveryNext(answerSubmitOnNext);
                    setResultAnswer(ansDataN);
                } else {
                    let answerSubmitOnNext = {
                        question_id: questions?.question_details.questions[attemptQuestion].id,
                        answer_id: 0,
                        completeTime: tabtimestamp,
                        parent_question_id: questions.question_id,
                        question_type_id: questions.question_type_id,
                        question_category_id: questions.question_category_id,
                        skip: 1,
                    }
                    submitResponseOnEveryNext(answerSubmitOnNext);
                }                 
                setAttemptQuestion(nextQueCount);
            } else {
                setPLoader(true);
                let ansDataN = resultAnswer;
                if (skip === 1) {
                    // ansDataN = {
                    //     ...ansDataN,
                    //     [questions?.question_details.questions[attemptQuestion].id]: {
                    //         question_id: questions?.question_details.questions[attemptQuestion].id,
                    //         answer_id: null,
                    //         result: 0,
                    //         completeTime: 0
                    //     },
                    // }; 

                    let answerSubmitOnNext = {
                        question_id: questions?.question_details.questions[attemptQuestion].id,
                        answer_id: 0,
                        completeTime: tabtimestamp,
                        parent_question_id: questions.question_id,
                        question_type_id: questions.question_type_id,
                        question_category_id: questions.question_category_id,
                        skip: 1,
                    }
                    submitResponseOnEveryNext(answerSubmitOnNext);
                } else {
                    ansDataN = {
                        ...resultAnswer,
                        [questions?.question_details.questions[attemptQuestion].id]: {
                            ...resultAnswer[questions?.question_details.questions[attemptQuestion].id],
                            completeTime: tabtimestamp
                        }
                    };

                    let answerSubmitOnNext = {
                        ...resultAnswer[questions?.question_details.questions[attemptQuestion].id],
                        completeTime: tabtimestamp,
                        parent_question_id: questions.question_id,
                        question_type_id: questions.question_type_id,
                        question_category_id: questions.question_category_id,
                    }
                    submitResponseOnEveryNext(answerSubmitOnNext);
                }
                handelFinalSubmit(typeStep, ansDataN, questions, false);
                setSkipComponent(true);
                setQuestionTime(10);
                setTime(10);
                setAttemptQuestion(0);
            }
            setProgressQuestion((nextQueCount / questionsLength) * 100);
        } else {
            handleOpen();
        }
    };

    const steps = (steps) => {
        if (questions?.question_type_slug === "code") {
            return <Code key={steps} mainKey={steps} id={steps} data={questions} count={steps} handleCodeChangeSubmit={handleCodeChange} firstStepGo={1} from={1}/>; // "from" prop for idententify official
        } else if (questions?.question_type_slug === "video") {
            return (
                <VideoRecording
                    key={steps}
                    mainKey={steps}
                    id={steps}
                    data={questions}
                    count={steps}
                    assessmentId={oldData.assessment_id}
                    candidateId={oldData.candidate_id}
                    invitationId={oldData.invitation_id}
                    handleFileUploadupdate={handleFileUploadupdate}
                    firstStepGo={1}
                    loderval={loderval}
                    setLoader={setLoader}
                />
            );
        } else if (questions?.question_type_slug === "essay") {
            return <Essay key={steps} mainKey={steps} id={steps} data={questions} count={steps} handleAnswerChange={handleAnswerChange} firstStepGo={1} />;
        } else if (questions?.question_type_slug === "file-upload") {
            return (
                <FileUpload
                    key={steps}
                    mainKey={steps}
                    id={steps}
                    data={questions}
                    count={steps}
                    assessmentId={oldData.assessment_id}
                    candidateId={oldData.candidate_id}
                    invitationId={oldData.invitation_id}
                    handleFileUploadupdate={handleFileUploadupdate}
                    firstStepGo={1}
                    loderval={loderval}
                    setLoader={setLoader}
                />
            );
        } else if (questions?.question_type_slug === "mcq") {
            if (questions?.question_details?.questions[steps]?.is_multiple === 1) {
                return (
                    <MCQMultiple
                        key={steps}
                        mainKey={steps}
                        id={steps}
                        data={questions}
                        count={steps}
                        handleRadioChangeMultiple={handleRadioChangeMultiple}
                        selectedValueMultiple={selectedValueMultiple}
                        firstStepGo={1}
                    />
                );
            } else {
                return <MCQSingle key={`O${steps}`} mainKey={steps} id={steps} data={questions} count={steps} handleRadioChange={handleRadioChange} firstStepGo={1} />;
            }
        }
    };

    const handleTimeOut = async () => {
        setPLoader(true);
        // let ansDataN = resultAnswer;
        // questions.question_details.questions.filter(result =>
        //     !Object.keys(ansDataN).includes(result.id.toString())
        // ).map((res, i) => (
        //     ansDataN = {
        //         ...ansDataN,
        //         [res.id]: {
        //             question_id: res.id,
        //             answer_id: null,
        //             result: 0,
        //             completeTime: 0
        //         }
        //     }
        // ));

        setTimeOut(true);
    }

    useEffect(() => {
        if (timeOut === true) {
            ; (async () => {
                let ansDataN = resultAnswer;

                let tabtimestamp = 0;
                if (startQuestionTime.current !== null) {
                    tabtimestamp = (questionLocalTime - getNewTimer);
                }                 

                let answerSubmitOnNext = {
                    question_id: questions?.question_details.questions[attemptQuestion].id,
                    // question_id: filteredQuestion.id,
                    answer_id: 0,
                    completeTime: tabtimestamp,
                    parent_question_id: questions.question_id,
                    question_type_id: questions.question_type_id,
                    question_category_id: questions.question_category_id,
                    skip: 1,
                }
                await submitResponseOnEveryNext(answerSubmitOnNext);

                await handelFinalSubmit(typeStep, ansDataN, questions, true);
                setSkipComponent(true);
                setQuestionTime(10);
                setTime(10);
                setAttemptQuestion(0);
                setPLoader(false);                 
            })();
        }
    }, [timeOut])

    useEffect(() => {
        setPLoader(false);
        setQuestionsLength(questions.question_details.questions.length);
    }, []);

    return (
        <>
            {
                pLoader ? <Loader /> :
                    <>
                        <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                            <Grid>
                                <img style={{ padding: "20px" }} src={Logo} alt="../../../../Assests/Images/FirstSectionImg.jpg" />
                            </Grid>
                            <TimerPage setNewTimer={setNewTimer} time={time} setTime={setTime} pauseTime={pauseTime}
                                skipComponent={skipComponent} formatTime={formatTime} attemptQuestion={attemptQuestion} progressQuestion={progressQuestion} questionTime={questionTime}
                                questionsLength={questionsLength} handleNext={handleNext} handleTimeOut={handleTimeOut}
                                bunchQuestionTime={bunchQuestionTime} extraTimeData={extraTimeData}
                            />

                            <Grid sx={{ padding: "20px" }}>
                                <Button
                                    onClick={skipComponent ? handleNext : handelNextSubmit}
                                    disabled={loderval}
                                    sx={{
                                        ...btnStyle,
                                        bgcolor: "var(--button-bg-color-assessment)",
                                        minWidth: "48px",
                                        minHeight: "48px",
                                        textTransform: "capitalize",
                                        padding: "0px 30px",
                                        "&:hover": {
                                            bgcolor: "var(--button-hover-bg-color-assessment)",
                                        },
                                    }}
                                    variant="contained"
                                >
                                    Next
                                    <ChevronRightIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        <Box style={showWarning}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    bgcolor: "#f6f6f6",
                                    position: "relative",
                                    minHeight: "90vh",
                                    display: "flex",
                                    justifyItems: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Container maxWidth="md"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        bgcolor: "#f6f6f6",
                                        position: "relative",
                                    }}
                                >
                                    <Box>
                                        <Grid>
                                            <Grid sx={{ ml: 13 }} item xs={10} md={12}>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    style={{
                                                        backgroundColor: "white",
                                                        padding: "55px",
                                                        boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                                                    }}
                                                >
                                                    <Grid item xs={12} md={12}>
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={11} md={11}>
                                                                <Typography
                                                                    id="transition-modal-title"
                                                                    variant="h6"
                                                                    component="h2"
                                                                    sx={{
                                                                        fontSize: "18px",
                                                                        fontWeight: 700,
                                                                    }}
                                                                >
                                                                    Are you sure you want to skip this question?
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={1} md={1}>
                                                                <CloseIcon
                                                                    onClick={handleClose}
                                                                    sx={{
                                                                        width: "20px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Typography
                                                            id="transition-modal-description"
                                                            sx={{
                                                                mt: 2,
                                                                fontSize: "13px",
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            You cannot come back to this question later. Even if you don't know the answer. Just pick the answer you feel best about. You might get it right!
                                                        </Typography>
                                                        <Grid container spacing={0}>
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    mt: 5,
                                                                    display: "flex",
                                                                    gap: "10px",
                                                                    justifyContent: "end",
                                                                }}
                                                                xs={12}
                                                                md={12}
                                                            >
                                                                <Button
                                                                    sx={{
                                                                        color: "var(--text-color-black)",
                                                                        bgcolor: "transparent",
                                                                        minWidth: "48px",
                                                                        minHeight: "48px",
                                                                        textTransform: "none",
                                                                        border: "2px solid var(--text-color-black)",
                                                                        boxShadow: "none",
                                                                        mb: 2,
                                                                        "&:hover": {
                                                                            bgcolor: "transparent",
                                                                            boxShadow: "none",
                                                                        },
                                                                    }}
                                                                    variant="contained"
                                                                    onClick={handleSkipQuestion}
                                                                >
                                                                    Skip question
                                                                </Button>

                                                                <Button
                                                                    sx={{
                                                                        ...btnStyle,
                                                                        bgcolor: "var(--button-bg-color-assessment)",
                                                                        minWidth: "48px",
                                                                        minHeight: "48px",
                                                                        textTransform: "capitalize",
                                                                        mb: 2,
                                                                        "&:hover": {
                                                                            bgcolor: "var(--button-hover-bg-color-assessment)",
                                                                        },
                                                                    }}
                                                                    variant="contained"
                                                                    onClick={handleClose}
                                                                >
                                                                    Add answer <ChevronRightIcon />
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Container>
                            </Box>
                            <Box
                                sx={{
                                    display: "block",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    bgcolor: "#f6f6f6",
                                    minHeight: "90vh",
                                    position: "relative",
                                }}
                            ></Box>
                        </Box>
                        {skipComponent ? <ParentComponent data={data} type={"official"} handleNext={handleNext} officialSteps={count} /> : <Box>{steps(attemptQuestion)}</Box>}
                    </>
            }
        </>
    );
});
export default Index;
