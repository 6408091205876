import { Box, Container, FormControlLabel, Grid, RadioGroup, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));

function MCQ(props) {
    const { data, count, handleRadioChangeMultiple, selectedValueMultiple } = props;

    return (
        <Box sx={{ minHeight: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Container
                maxWidth="lg"
                style={{
                    backgroundColor: "white",
                    padding: "40px",
                    borderRadius: "12px",
                }}
            >
                <Box>
                    <Grid container spacing={2}>
                        <Grid item sx={{ px: 5 }} xs={12} md={6}>
                            <Item>
                                <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                                    Question
                                </Typography>

                                <Typography variant="body1" sx={{mt: 2 }}>
                                    {" "}
                                    <div dangerouslySetInnerHTML={{ __html: data.question_details?.questions[count].question }} />
                                </Typography>
                            </Item>
                        </Grid>
                        <Grid item sx={{ px: 5 }} xs={12} md={6}>
                            <Item>
                                <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                                    Select multiple answer
                                </Typography>
                                <Grid sx={{maxHeight:"60vh", overflowY:"scroll"}}>
                                <RadioGroup
                                    style={{ flexDirection: "column" }}
                                    row
                                    aria-label="radio-group-english"
                                    name="fluentEnglish"
                                    onChange={(e) => handleRadioChangeMultiple(e, data.question_details.questions[count])}
                                >
                                    {data.question_details.questions[count].options.map((item) => (
                                        <Grid className={selectedValueMultiple.includes(parseInt(item.id)) ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}>
                                            <FormControlLabel
                                                sx={{ width: "100%" }}
                                                value={item.id}
                                                control={<Checkbox inputProps={{ "aria-label": "controlled" }} icon={<RadioButtonUncheckedIcon sx={{fontSize:"35px", color:"#ededed"}} />} checkedIcon={<RadioButtonCheckedIcon sx={{fontSize:"35px", color:"white"}} />} />}
                                                label={
                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: 500,
                                                        }}
                                                        dangerouslySetInnerHTML={{ __html: item.options }}
                                                    ></span>
                                                }
                                                onChange={(e) => handleRadioChangeMultiple(e, data.question_details.questions[count])}
                                            />
                                        </Grid>
                                    ))}
                                </RadioGroup>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default MCQ;
