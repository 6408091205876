import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Logo from "../../../Assests/Images/LOGO.png";
import { styled } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Paper from "@mui/material/Paper";
import { useNavigate, useLocation } from "react-router-dom";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import Loader from "../../Common/Loader";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
};

export function TransitionsModal({ open, setOpen, checkCamera, from }) {
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                className="RemoveOutLine"
                sx={{ backdropFilter: "blur(4px)" }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography sx={{ fontSize: "22px", fontWeight: 700 }} variant="h6" component="h2">
                            Mandatory {from === 1 ? "Webcam!!!" : "Screen Recording!!!"}
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 2, fontWeight: 500, fontSize: "16px" }}>
                            For this test, you must have to {from === 1 ? "Enable your Webcam and MicroPhone." : "Enable Screen Recording."}
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 2, fontWeight: 400, fontSize: "14px" }}>
                            If you are Facing any issue, Go back and Try again..
                            <br />
                            You can also contact to our team
                        </Typography>
                        <Grid sx={{ mt: 3 }} container spacing={0}>
                            <Grid xs={12} md={12}>
                                {" "}
                                <Grid container spacing={0}>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "end",
                                        }}
                                        xs={12}
                                        md={12}
                                    >
                                        <Button
                                            sx={{
                                                bgcolor: "var(--text-color-Green)",
                                                minWidth: "48px",
                                                minHeight: "48px",
                                                textTransform: "none",
                                                boxShadow: "none",
                                                mb: 2,
                                                "&:hover": {
                                                    bgcolor: "#e0e0e0",
                                                    boxShadow: "none",
                                                },
                                            }}
                                            variant="contained"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));
const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

function Index() {
    const mediaRecorder = useRef(null);
    const recordedChunks = useRef([]);
    const videoRef = useRef(null);
    const startTime = useRef(null);
    const location = useLocation();
    const oldData = location.state?.data;
    const ExtraTimeData = location.state?.time;
    const [isCameraAvailable, setIsCameraAvailable] = useState(null);
    const navigate = useNavigate();
    const [loaderForCamera, setLoaderForCamera] = useState(false);
    const [loaderForMicrophone, setLoaderForMicrophone] = useState(false);
    const [antiCheating, setAntiCheating] = useState(oldData.anti_chating_data);
    const [open, setOpen] = React.useState(false);
    const [mandatoryCameraErr, setMandatoryCameraErr] = useState(false);
    const [videoDevices, setVideoDevices] = useState([]);
    const ITEM_HEIGHT = 48;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openIcon = Boolean(anchorEl);
    const [currentDeviceId, setCurrentDeviceId] = useState(null);
    const [formData, setFormData] = useState({
        ...oldData,
        cameraDeviceId:""
    });
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (Id) => {
        if (currentDeviceId !== Id) {
            setCurrentDeviceId(Id);
            startRecording(Id);
        }
        setAnchorEl(null);
    };

    const startRecording = async (deviceId) => {
        setLoaderForMicrophone(true);
        setFormData({ ...formData, cameraDeviceId: deviceId });
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: {
                        exact: deviceId,
                    },
                },
                audio: true,
            });
            videoRef.current.srcObject = stream;
            setCurrentDeviceId(deviceId);
            startTime.current = new Date();
            var options = { mimeType: "video/webm; codecs=vp9" };
            const recorder = new MediaRecorder(stream, options);
            recorder.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    recordedChunks.current.push(e.data);
                }
            };
            recorder.onstop = () => {
                recordedChunks.current = [];
            };
            recorder.start();
            mediaRecorder.current = recorder;

            const video = videoRef.current;
            video.addEventListener("play", () => {});
            setLoaderForMicrophone(false);
        } catch (error) {
            console.error("Error accessing webcam and/or microphone:", error);
            if (antiCheating.is_mandatory_webcam) {
                setMandatoryCameraErr(true);
                setOpen(true);
            }
            setLoaderForMicrophone(false);
        }
    };

    const checkCamera = async () => {
        setLoaderForCamera(true);
        try {
            if ("mediaDevices" in navigator) {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true,
                });
                const mediaDevices = await navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
                    let divices = mediaDevices.filter((x) => x.kind === "videoinput");
                    setVideoDevices(divices);
                    return divices[0].deviceId;
                });
                setIsCameraAvailable(true);
                startRecording(mediaDevices);
            } else {
                setIsCameraAvailable(null);
                if (antiCheating.is_mandatory_webcam) {
                    setMandatoryCameraErr(true);
                    setOpen(true);
                }
            }
            setLoaderForCamera(false);
        } catch (error) {
            setIsCameraAvailable(false);
            if (antiCheating.is_mandatory_webcam) {
                setMandatoryCameraErr(true);
                setOpen(true);
            }
            setLoaderForCamera(false);
        }
    };

    useEffect(() => {
        if (antiCheating.is_snapshot_enable === true) {
            checkCamera();
        }
    }, []);

    const handelNext = () => {
        navigate("/questions", { state: { time: ExtraTimeData, data: formData } });
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f6f6f6",
                minHeight: "100vh",
                position: "relative",
            }}
        >
            <HelmetProvider>
                <Helmet>
                    <title>Camera Setup - TestnHire</title>
                </Helmet>
            </HelmetProvider>
            <Container maxWidth="lg">
                <Box>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            backgroundColor: "white",
                            padding: "55px",
                            boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                        }}
                    >
                        <Grid item xs={12} md={6}>
                            <Item sx={{ padding: "5px 15px" }}>
                                <Typography variant="h2" sx={{ fontSize: "24px", fontWeight: 400 }}>
                                    <span style={{ fontSize: "22px", fontWeight: 700 }}>Camera Setup</span>
                                </Typography>
                                <Divider sx={{ mt: 2, borderWidth: "1px", borderColor: "green" }} />
                                <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}>
                                    We use camera images to ensure fairness for everyone. Make sure that you are in front of your camera.
                                </Typography>
                                <Divider sx={{ mt: 3, borderWidth: "1px", borderColor: "#edeeef" }} />
                                <Grid item sx={{ mt: 2 }} container spacing={0}>
                                    <Grid item sx={{ display: "flex", alignItems: "center" }} xs={6}>
                                        <VideocamOutlinedIcon sx={{ mr: 1 }} />
                                        <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 700 }}>
                                            Camera:
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ display: "flex", justifyContent: "end" }} xs={6}>
                                        <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={openIcon ? "long-menu" : undefined}
                                            aria-expanded={openIcon ? "true" : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e)}
                                        >
                                            <MoreVertOutlinedIcon
                                                sx={{
                                                    color: "var(--text-color-black)",
                                                    borderRadius: "5px",
                                                    padding: "15px ",
                                                    fontSize: "50px",
                                                    bgcolor: "#EDEDED",
                                                }}
                                            />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                "aria-labelledby": "long-button",
                                            }}
                                            anchorEl={anchorEl}
                                            open={openIcon}
                                            onClose={(e)=>handleClose(currentDeviceId)}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5,
                                                    width: "20ch",
                                                },
                                            }}
                                        >
                                            {videoDevices.length > 0 ?
                                                videoDevices.map((option) => (
                                                    <MenuItem key={option.deviceId} selected={option.deviceId === currentDeviceId} onClick={(e) => handleClose(option.deviceId)}>
                                                        {option.label}
                                                    </MenuItem>
                                                )):
                                            <MenuItem >
                                                No camera Found
                                            </MenuItem>}
                                        </Menu>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Item>
                                <Grid>
                                    {loaderForCamera || (loaderForMicrophone && <Loader />)}
                                    {isCameraAvailable === true ? (
                                        <video ref={videoRef} autoPlay muted style={{ width: "100%", maxWidth: "500px" }}></video>
                                    ) : (
                                        <Grid className="cameraSetupWarning" container spacing={0}>
                                            <Grid item sx={{ display: "flex", alignItems: "center" }} xs={1}>
                                                <ReportProblemOutlinedIcon />
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400 }}>
                                                    It seems you don't have a camera or mic connected to your computer or they are blocked. To enable the camera and mic, click on the camera blocked
                                                    icon in your browser's address bar and reload the page. If you don't enable a camera, you can still take the assessment, but then thedriftpoint
                                                    cannot verify fair play.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid className="cameraSetupTroubleShoot">
                                    <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: 700, mt: 2, mb: 2 }}>
                                        Trouble with your webcam?
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}>
                                        Ensure you have granted permission for your browser to access your camera.
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}>
                                        Ensure you are using a <span style={{ fontWeight: 700 }}>supported browser.</span>
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}>
                                        If you have multiple camera devices, ensure you have given your browser and our website permission to use the right device.
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}>
                                        Try launching the assessment in incognito mode or in a private window.
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}>
                                        Ensure your camera drivers and web browser are up to date.
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2, mb: 3 }}>
                                        Restart your device and try accessing the assessment again using the link in the invitation email.
                                    </Typography>
                                </Grid>
                                <Grid sx={{ display: "flex", justifyContent: "end" }}>
                                    <Button
                                        sx={{
                                            ...btnStyle,
                                            bgcolor: "var(--button-bg-color-assessment)",
                                            minWidth: "48px",
                                            minHeight: "48px",
                                            textTransform: "capitalize",
                                            mt: 5,
                                            mb: 2,
                                            "&:hover": {
                                                bgcolor: "var(--button-hover-bg-color-assessment)",
                                            },
                                        }}
                                        disabled={loaderForCamera || loaderForMicrophone || mandatoryCameraErr}
                                        onClick={handelNext}
                                        variant="contained"
                                    >
                                        Next <ChevronRightIcon />
                                    </Button>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Grid>
                <img
                    style={{
                        position: "fixed",
                        bottom: "10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        opacity: 0.35,
                    }}
                    src={Logo}
                    alt="../../../../Assests/Images/FirstSectionImg.jpg"
                />
            </Grid>
            <TransitionsModal open={open} setOpen={setOpen} from={1} />
        </Box>
    );
}

export default Index;
