import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Logo from "../../../../Assests/Images/LOGO.png";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {FRONT_Url} from "../../../../Constants/ConstantsUrl";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));

function Index() {
    useEffect(()=>{
        console.log("REACT_APP_HOME_URL:::", FRONT_Url)
        setTimeout(function(){
            window.location.href = FRONT_Url;
        },3000);
    },[]);
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f6f6f6",
                minHeight: "100vh",
                position: "relative",
            }}
        >
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Finish Assessment - TestnHire</title>
                        
                    </Helmet>
                </div>
            </HelmetProvider>
            <Container maxWidth="lg" >
                <Box >
                    <Grid container spacing={0} style={{justifyContent:"center"}}>
                        <Grid item xs={10} md={6}>
                            
                            <Grid container spacing={0} style={{ backgroundColor: "white", 
                                padding: "55px",
                                boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                            }}>
                                <Grid item xs={12} md={12}>
                                    <Item>
                                        <Typography variant="h2" sx={{ fontSize: "24px", fontWeight: 400, }}>
                                            <span style={{ fontSize: "22px", fontWeight: 700 }}>Thanks for completing this assessment</span>
                                        </Typography>
                                        <Divider sx={{ mt: 1, borderWidth: "1px", borderColor: "green" }} />

                                        <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 3 }}>
                                            You can close this window now.
                                        </Typography>                                        
                                    </Item>
                                </Grid>
                            </Grid>
                    
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Grid>
                <img
                    style={{
                        position: "fixed",
                        bottom: "10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        opacity: 0.35,
                    }}
                    src={Logo}
                    alt="../../../../Assests/Images/FirstSectionImg.jpg"
                />
            </Grid>
        </Box>
    );
}

export default Index;
