import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { Box, TextField, Container, Button, Stack, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { getHeaders, postPublicAssessmentInvite, checkStatusPublicAssessmentInvite, FRONT_Url } from "../../Constants/ConstantsUrl";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader";

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#E0E3E7",
                        "--TextField-brandBorderHoverColor": "#B2BAC2",
                        "--TextField-brandBorderFocusedColor": "var(--border-color-Green)",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
    boxShadow: "none",
    padding: "5px",
}));

const Index = () => {
    const navigate = useNavigate();
    const url = window.location.href;
    const urlParts = url.split("/");
    const [notificationOpen, setNotificationOpen] = useState(false);
    const userToken = urlParts[urlParts.length - 2];
    const userInvitationToken = urlParts[urlParts.length - 1];
    const [startLoader, setStartLoader] = useState(false);
    const [emailError, setEmailError] = React.useState("");
    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };
    const [loaderForSource, setLoaderForSource] = useState(true);
    const [formData, setFormData] = useState({
        email: ""
    });
    const [formErrors, setFormErrors] = useState({
        email: false
    });
    const checkActiveSource = async () => {
        const headers = getHeaders();
        const data = { 
            public_url: userToken,
            user_invitation_token: userInvitationToken 
        };
        try {
            await axios
                .post(checkStatusPublicAssessmentInvite, data, { headers })
                .then((response) => response)
                .then((response) => {
                    if (response.data.status === 200) {
                        if (response.data.data.public_url_status === 0) {
                            window.location.href = FRONT_Url;
                        } else {
                            setLoaderForSource(false);
                        }
                    }
                });
        } catch (error) {
            if (error.response?.status === 403) {
                navigate("/assessment-submitted", { state: { data: data, formData: formData } });
            } else if (error.response?.status === 404) {
                navigate("/assessment-not-found", { state: { data: data, formData: formData } });
            }
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        checkActiveSource();
    }, [loaderForSource]);

    const handleOnChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setFormErrors({ ...formErrors, [e.target.name]: false });
        setEmailError(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    };

    const checkvalidEmail = (email) => {
        let emailRegex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x09\x0b\x0c\x0e-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x09\x0b\x0c\x0e-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        if (!emailRegex.test(email)) {
            return 0; // Invalid email
        } else {
            return 1; // Valid email
        }
    };

    const handleSubmit = async (e) => {
        setStartLoader(true);
        e.preventDefault();
        setEmailError("");
        const errors = {};
        if (!formData.email.trim()) {
            // setEmailError(true);
            errors.email = true;
            setStartLoader(false);
            // return false;
        }
        if (!checkvalidEmail(formData.email)) {
            // setFormErrors({ ...formErrors, email: true});
            setEmailError("Invalid Email");
            setStartLoader(false);
            return false;
        }

        if (Object.entries(errors).some((error) => error)) {
            setFormErrors(errors);
            return;
        }
        try {
            const headers = getHeaders();
            const data = {
                user_details: [
                    {
                        email: formData?.email,
                    },
                ],
            };

            await axios
            .post(postPublicAssessmentInvite + userToken + '/' + userInvitationToken, data, { headers })
                .then((response) => response)
                .then((res) => {
                    if (res?.data?.status === 200) {
                        setNotificationOpen(true);
                        setFormData({
                            email: "",                             
                        });
                        setStartLoader(false);
                        setTimeout(() => {        
                            navigate("/");
                        }, 6000);
                    } else if (res?.data?.status === 201) {
                        let  existingCandidate=res?.data?.data?.existing_candidates;  
                        if (existingCandidate.length>0){
                            setEmailError("You had already Invited for the Assessment");
                        } else{      
                            setEmailError("Credits Exhausted, Please Contact Organization");
                        }
                        setStartLoader(false);
                    } else {
                        alert(JSON.stringify(res.errors));
                    }
                });
        } catch (error) {
            if (error.response.status === 400 || error.response.status === 500) {
                setEmailError(error.response.data.message);
            }
            if (error.response.status === 422) {
                if (error.response.data.data) {
                    setEmailError(error.response.data.data);
                } else {
                    setEmailError("");
                }
            }
        }
    };
    const outerTheme = useTheme();
    return (
        <div>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Public Invitation - TestnHire</title>
                    </Helmet>
                </div>
            </HelmetProvider>
            {loaderForSource ? (
                <Loader />
            ) : (
                <Container sx={{ mt: "120px" }} maxWidth="xl">
                    <Box sx={{ boxShadow: "none", border: "none", margin: "2px" }}>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                            <Grid xs={12} md={4}></Grid>
                            <Grid
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "none",
                                    boxShadow: "none",
                                }}
                                xs={12}
                                md={4}
                            >
                                <Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 15 }}>
                                    <Item
                                        style={{
                                            boxShadow: "unset",
                                            borderRadius: "none",
                                            background: "none",
                                        }}
                                    >
                                        <Box
                                            component="form"
                                            sx={{
                                                "& > :not(style)": {
                                                    mt: 3,
                                                },
                                            }}
                                            style={{ width: "100%" }}
                                            onSubmit={handleSubmit}
                                        >
                                            <Grid
                                                container
                                                sx={{ mt: 0 }}
                                                rowSpacing={1}
                                                columnSpacing={{
                                                    xs: 0,
                                                    sm: 0,
                                                    md: 0,
                                                }}
                                            >
                                                <Grid container spacing={0} sx={{ mt: 3 }}>
                                                    <Grid sx={{ textAlign: "center" }} item xs={12} md={12}>
                                                        <Typography variant="h1" sx={{ fontSize: "40px", fontWeight: 700 }}>
                                                            Test-N-Hire
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            sx={{
                                                                fontSize: "18px",
                                                                fontWeight: 700,
                                                                my: 1,
                                                            }}
                                                        >
                                                            Make assessment
                                                        </Typography>
                                                        <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400 }}>
                                                            Thank you for your interest in this position! Enter your email address and we will send you more information and a link to the assessment.
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                {/* <Grid sx={{ mt: 2 }} item xs={12} md={12}>
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            id="filled-basic"
                                                            label="First Name"
                                                            variant="filled"
                                                            name="first_name"
                                                            type="first_name"
                                                            onChange={handleOnChange}
                                                            onKeyDown={handleKeyPress}
                                                            error={formErrors.first_name || emailError.length > 0}
                                                            helperText={(formErrors.first_name && "First Name is required") || emailError}
                                                            InputProps={{
                                                                style: {
                                                                    backgroundColor: "white",
                                                                    border: "0.1px solid #e1e1e1",
                                                                    borderRadius: "5px",
                                                                },
                                                            }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    fontSize: "13px",
                                                                    fontWeight: 300,
                                                                },
                                                            }}
                                                        />
                                                    </ThemeProvider>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            id="filled-basic"
                                                            label="Last Name"
                                                            variant="filled"
                                                            name="last_name"
                                                            type="last_name"
                                                            onChange={handleOnChange}
                                                            onKeyDown={handleKeyPress}
                                                            error={formErrors.last_name || emailError.length > 0}
                                                            helperText={(formErrors.last_name && "Last Name is required") || emailError}
                                                            InputProps={{
                                                                style: {
                                                                    backgroundColor: "white",
                                                                    border: "0.1px solid #e1e1e1",
                                                                    borderRadius: "5px",
                                                                },
                                                            }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    fontSize: "13px",
                                                                    fontWeight: 300,
                                                                },
                                                            }}
                                                        />
                                                    </ThemeProvider>
                                                </Grid> */}
                                                <Grid item xs={12} md={12}>
                                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                                        <TextField
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            id="filled-basic"
                                                            label="Email Address"
                                                            variant="filled"
                                                            name="email"
                                                            onChange={handleOnChange}
                                                            onKeyDown={handleKeyPress}
                                                            error={formErrors.email || emailError.length > 0}
                                                            helperText={(formErrors.email && "Email is required") || emailError}
                                                            InputProps={{
                                                                style: {
                                                                    backgroundColor: "white",
                                                                    border: "0.1px solid #e1e1e1",
                                                                    borderRadius: "5px",
                                                                },
                                                            }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    fontSize: "13px",
                                                                    fontWeight: 300,
                                                                },
                                                            }}
                                                        />
                                                    </ThemeProvider>
                                                </Grid>
                                            </Grid>
                                            <Stack
                                                sx={{
                                                    mt: 5,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                                spacing={2}
                                                direction="row"
                                            >
                                                <Button
                                                    style={{
                                                        padding: "15px 30px",
                                                        border: "none",
                                                        backgroundColor: "var(--button-bg-color-green)",
                                                        color: "var(--text-color-OffWhite)",
                                                        borderRadius: "5px",
                                                    }}
                                                    type="submit"
                                                >
                                                    {" "}
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 600,
                                                            textTransform: "none",
                                                        }}
                                                    >
                                                        {startLoader ? "Submiting..." : "Submit"}
                                                    </Typography>
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid xs={12} md={4}></Grid>
                        </Grid>
                    </Box>
                </Container>
            )}
            <Snackbar open={notificationOpen} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert
                    onClose={handleNotificationClose}
                    severity="success"
                    sx={{
                        width: "500px",
                        justifyContent: "center",
                        backgroundColor: "#cbd7cb",
                        color: "var(--text-color-black)",
                    }}
                >
                    An invitation has been sent to your email address.
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Index;
