import { Box, Container, Divider, Grid, Typography, Button } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));
const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

// import useMediaQuery from "@mui/material/useMediaQuery";

function Index(props) {
    const { handleNext, totalQualify,candidateName } = props;
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f6f6f6",
                minHeight: "60vh",
                position: "relative",
            }}
        >
            <Container maxWidth="xxl">
                <Box>
                    <Grid container spacing={0} style={{ justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={10} md={6}>
                            <Grid
                                container
                                spacing={0}
                                style={{
                                    backgroundColor: "white",
                                    padding: "55px 140px",
                                    boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                                }}
                            >
                                <Grid item xs={12} md={12} sx={{ justifyContent: "center", alignItems: "center" }}>
                                    <Item>
                                        <Typography variant="body1" sx={{ fontSize: "24px", fontWeight: 400 }}>
                                            Hello <strong>{candidateName},</strong>
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: 400, mt: 3 }}>
                                            As a First Step in this Assessment, you will be asked to answer {totalQualify} Qualifying Questions. If you meet the criteria, you will be taken to the
                                            first test. Otherwise, the assesment will end after the qualifying question.
                                        </Typography>
                                        <Divider
                                            sx={{
                                                my: 2,
                                                borderWidth: "1px",
                                                borderColor: "#d9d9d9",
                                            }}
                                        />
                                        <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 3 }}>
                                            Please stay on this screen. The timer for your next test has <span style={{ fontWeight: 700 }}>started</span> and{" "}
                                            <span style={{ fontWeight: 700 }}>cannot be paused</span>{" "}
                                        </Typography>
                                        <Grid sx={{ mt: 5, textAlign: "center" }}>
                                            <Button
                                                onClick={handleNext}
                                                sx={{
                                                    ...btnStyle,
                                                    bgcolor: "var(--button-bg-color-assessment)",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "capitalize",
                                                    padding: "10px 40px",
                                                    "&:hover": {
                                                        bgcolor: "var(--button-hover-bg-color-assessment)",
                                                    },
                                                }}
                                                variant="contained"
                                            >
                                                Start Test <ArrowForwardIcon sx={{ ml: 2 }} />
                                            </Button>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default Index;
