import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "../../../Assests/Images/LOGO.png";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));

// import useMediaQuery from "@mui/material/useMediaQuery";

function Index() {
    const location = useLocation();
    const oldData = location.state?.data;
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(10);
    useEffect(()=>{
        if (seconds > 0) {
            const timerId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);

                return () => clearInterval(timerId);
            } else {
                if(oldData.intro_outro_video_path?.outro_video_path !=='' && oldData.intro_outro_video_path?.outro_video_path !==null){
                    navigate("/outro-video", { state: { data: oldData } });
                }else{
                    navigate("/feedback", { state:{ data:oldData}});
                }
          }
    },[seconds])

    return (
        <Box
            sx={{
                bgcolor: "#f6f6f6",
                minHeight: "100vh",
                position: "relative",
            }}
        >
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Disqualified - TestnHire</title>
                    </Helmet>
                </div>
            </HelmetProvider>
            <Grid sx={{ justifyContent: "space-between", display: "flex",backgroundColor: "white", border:"1px solid #ddd8d8" }}>
            <Container maxWidth="xl">
                <Grid >
                    <Grid>
                        <img style={{ padding: "20px" }} src={Logo} alt="../../../../Assests/Images/FirstSectionImg.jpg" />
                    </Grid>
                </Grid>
            </Container>
            </Grid>
            <Container maxWidth="sm" sx={{marginTop:10}}>
                <Box>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            backgroundColor: "white",
                            padding: "45px",
                            borderRadius:"10px",
                            boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                            border:"1px solid #ddd8d8",
                        }}
                    >
                        <Grid item xs={12} md={12}>
                            <Item sx={{ padding: "5px 15px" }}>
                                <Typography variant="body1" sx={{ fontSize: "24px", fontWeight: 700, mt: 2 }}>
                                    Assessment Complete
                                </Typography>
                                <Divider sx={{ mt: 1, borderWidth: "1px", borderColor: "black" }} />
                                <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: 500, mt: 2 }}>
                                Unfortunately, you do not meet the requirements for this position. This is based on your answers to the pre-qualifying questions. We thank you for your time and wish you success in your job search.
                                </Typography>
                                <Typography>redirect after {seconds} seconds ...</Typography>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Grid>
                <img
                    style={{
                        position: "fixed",
                        bottom: "50px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        opacity: 0.35,
                    }}
                    src={Logo}
                    alt="../../../../Assests/Images/FirstSectionImg.jpg"
                />
            </Grid>
        </Box>
    );
}

export default Index;
