import { Box, Button, Checkbox, Container, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Logo from "../../../Assests/Images/LOGO.png";
import { styled } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { CandidateDetailsFill } from "../../../Constants/ConstantsUrl";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));
const btnStyle = {
    color: "white",
    boxShadow: "unset",
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };

function Index() {
    const navigate = useNavigate();
    const location = useLocation();
    const oldData = location.state?.data;
    const oldFormData = location.state?.formData;
    const [checkDisablity, setCheckDisablity] = useState(false);
    const [antiCheating, setAntiCheating] = useState(oldData.anti_chating_data);
    const [formData, setFormData] = useState({
        ...oldFormData,
        fluentEnglish: "",
        testCondition: "",
        knowMyCondition: false,
    });
    const [formDataError, setFormDataError] = useState({
        fluentEnglish: "",
        testCondition: "",
    });
    const [loaderForLocation, setLoaderForLocation] = useState(false);
    const [locationData, setLocationData] = useState(null);

    const handleRadioChange1 = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        setFormDataError({ ...formDataError, [event.target.name]: "" });
    };

    const handleRadioChange2 = (event) => {
        if (parseInt(event.target.value) === oldData.is_disabled && event.target.name === "testCondition") {
            setCheckDisablity(true);
        } else {
            setCheckDisablity(false);
        }
        setFormData({ ...formData, [event.target.name]: event.target.value });
        setFormDataError({ ...formDataError, [event.target.name]: "" });
    };

    const handleCheckBoxChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.checked ? true : false });
    };

    const handelSubmit = async () => {
        if (formData.fluentEnglish === "" || formData.testCondition === "") {
            if (formData.fluentEnglish === "") {
                setFormDataError((prevErrors) => ({
                    ...prevErrors,
                    fluentEnglish: "Select any one option",
                }));
            }
            if (formData.testCondition === "") {
                setFormDataError((prevErrors) => ({
                    ...prevErrors,
                    testCondition: "Select any one option",
                }));
            }
            return false;
        }

        const formDataAll = new FormData();
        formDataAll.append("invitation_id", oldData?.invitation_id);
        formDataAll.append("is_fluent_in_lang", formData.fluentEnglish);
        formDataAll.append("is_disabled", formData.testCondition);
        formDataAll.append("my_condition", formData.knowMyCondition);
        formDataAll.append("first_name", formData.firstName);
        formDataAll.append("last_name", formData.lastName);
        formDataAll.append("send_report", 1);
        try {
            const response = await axios.post(CandidateDetailsFill, formDataAll);
            const res = response.data;
            if (res.status === 200) {
                let extraTime = 0;

                
                if (formData.fluentEnglish == "0") {
                    extraTime += oldData.extra_time_english;
                }

                if (formData.testCondition == "1") {
                    extraTime += oldData.extra_time;
                }
                 
                if (extraTime > 0) {
                    navigate("/extra-time", {
                        state: {
                            time: extraTime,
                            data: oldData,
                        },
                        replace: true,
                    });
                } else {
                    if (antiCheating.is_snapshot_enable === true) {
                        navigate("/camera-setup", { state: { time: 0, data: oldData } });
                    } else {
                        navigate("/questions", { state: { time: 0, data: oldData } });
                    }
                }                 
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    function handleLocationClick() {
        setLoaderForLocation(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
        } else {
            console.log("Geolocation not supported");
            setLoaderForLocation(false);
        }
    }

    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setLocationData({ latitude, longitude });
        displayLocation(latitude, longitude);
        setLoaderForLocation(false);
    }
    function error() {
        console.log("Unable to retrieve your location");
        setLoaderForLocation(false);
    }
    async function displayLocation(latitude, longitude) {
        try {
            var url = `https://nominatim.openstreetmap.org/reverse.php?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
            const response = await axios.get(url);
            const res = response.data;
            oldData.locationDis = res.display_name;
        } catch (e) {
            console.log("error", e);
        }
    }

    useEffect(() => {
        handleLocationClick();
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f6f6f6",
                minHeight: "100vh",
                position: "relative",
            }}
        >
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Check Assessment - TestnHire</title>
                    </Helmet>
                </div>
            </HelmetProvider>
            <Container maxWidth="lg">
                <Box>
                    <Grid container spacing={2} style={{ justifyContent: "center" }}>
                        <Grid item xs={10} md={6}>
                            <Grid
                                container
                                spacing={2}
                                style={{
                                    backgroundColor: "white",
                                    padding: "55px",
                                    boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                                }}
                            >
                                <Grid item xs={12} md={12}>
                                    <Item>
                                        <Typography
                                            variant="h2"
                                            sx={{
                                                textAlign: "center",
                                                fontSize: "24px",
                                                fontWeight: 400,
                                                my: 3,
                                            }}
                                        >
                                            <span style={{ fontSize: "22px", fontWeight: 700 }}>Assessment Setup</span>
                                        </Typography>
                                        <Divider sx={{ mt: 5, borderWidth: "1px", borderColor: "green" }} />

                                        <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}>
                                            To make sure that everyone is fairly treated, we might need to adjust your assessment setup. You can help us do that by answering the following questions:
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2, mb: 2 }}>
                                            Are you fluent in English?
                                        </Typography>
                                        <FormControl component="fieldset" style={{ width: "100%" }}>
                                            <RadioGroup
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                }}
                                                row
                                                aria-label="radio-group-english"
                                                name="fluentEnglish"
                                                onChange={handleRadioChange1}
                                            >
                                                <Grid
                                                    container
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Grid item md={5} className={formData.fluentEnglish === "1" ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}>
                                                        <FormControlLabel value="1" control={<Radio />} label="Yes, I am fluent" />
                                                    </Grid>
                                                    <Grid item md={5} className={formData.fluentEnglish === "0" ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}>
                                                        <FormControlLabel value="0" control={<Radio />} label="No, I am not fluent" />
                                                    </Grid>
                                                </Grid>
                                            </RadioGroup>
                                            {formDataError.fluentEnglish && <span className="error_msg_style">{formDataError.fluentEnglish}</span>}
                                        </FormControl>
                                        <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}>
                                            Persons with disabilities or conditions that affect memory or concentration abilities, such as ADHD, dyslexia or autism, may require assessment change(s) in
                                            order to proceed. Do you require additional support to complete our online assessment?
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}>
                                            If you don't consider your condition to be a hindrance when taking a test, you can answer ”No” to proceed to the assessment.
                                        </Typography>
                                        <FormControl component="fieldset" style={{ width: "100%" }}>
                                            <RadioGroup
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                }}
                                                row
                                                aria-label="radio-group-condition-test"
                                                name="testCondition"
                                                onChange={handleRadioChange2}
                                            >
                                                <Grid
                                                    container
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        mt: 2,
                                                    }}
                                                >
                                                    <Grid item md={5} className={formData.testCondition === "1" ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}>
                                                        <FormControlLabel value="1" control={<Radio />} label="Yes, I do" />
                                                    </Grid>
                                                    <Grid item md={5} className={formData.testCondition === "0" ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}>
                                                        <FormControlLabel value="0" control={<Radio />} label="No, I don't" style={{ fontSize: "10px" }} />
                                                    </Grid>
                                                </Grid>
                                            </RadioGroup>
                                            {formDataError.testCondition && <span className="error_msg_style">{formDataError.testCondition}</span>}
                                        </FormControl>
                                        {checkDisablity === true ? (
                                            <Grid
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                <Checkbox {...label} name="knowMyCondition" onChange={handleCheckBoxChange} />
                                                <Typography variant="body1" sx={{ fontSize: "13px", fontWeight: 500 }}>
                                                    Let the employer know about my condition (optional)
                                                </Typography>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}

                                        <Divider
                                            sx={{
                                                my: 3,
                                                borderWidth: "1px",
                                                borderColor: "#edeeef",
                                            }}
                                        />
                                        <Grid sx={{ display: "flex", justifyContent: "end" }}>
                                            <Button
                                                onClick={handelSubmit}
                                                disabled={loaderForLocation}
                                                sx={{
                                                    ...btnStyle,
                                                    bgcolor: "var(--button-bg-color-assessment)",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "capitalize",
                                                    "&:hover": {
                                                        bgcolor: "var(--button-hover-bg-color-assessment)",
                                                    },
                                                }}
                                                variant="contained"
                                            >
                                                Continue <ChevronRightIcon />
                                            </Button>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Grid>
                <img
                    style={{
                        position: "fixed",
                        bottom: "10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        opacity: 0.35,
                    }}
                    src={Logo}
                    alt="../../../../Assests/Images/FirstSectionImg.jpg"
                />
            </Grid>
        </Box>
    );
}

export default Index;
