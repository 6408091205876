import { Box, Button, Checkbox, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Logo from "../../../Assests/Images/LOGO.png";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { TalentAssessmentCheck,UpdateCandidateFirstAndLastName } from "../../../Constants/ConstantsUrl";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";


const btnStyle = {
    color: "white",
    boxShadow: "unset",
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#0000001f", //borderColor
                        "--TextField-brandBorderHoverColor": "#276678", //hover
                        "--TextField-brandBorderFocusedColor": "#276678", //ActiveColor
                        "& label.Mui-focused": {
                            color: "#276678", //activeText
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });

function Index() {

    const navigate = useNavigate();

    const outerTheme = useTheme();
    const [data, setData] = useState({});
    const [formData, setFormData] = useState({
        firstName: "",
        email: "",
        lastName: "",
        token : "",
        accept: false,
        marketing: false
    });
    const [formDataError, setFormDataError] = useState({
        firstName: "",
        lastName: "",
        email:"",
        accept: ""
    });
    const [showEmailTextBox, setShowEmailTextBox] = useState(false);
    const [load, setLoad] = useState(true);
    const [submitload, setSubmitLoad] = useState(false);
    const { token } = useParams();
    const checkValidAssessment = async () => {
        try {
            if(load){
                setLoad(false);

                const response = await axios.get(TalentAssessmentCheck+token);
                const res = response.data;
                
                if (res.status) {
                    setData(res.data);
                    if(res.data.email !== null){

                        setFormData((prevData) => ({ 
                            ...prevData, 
                            email: res.data.email,
                            token: token
                        }));

                        if(res.data.first_name !== null) {
                            setFormData((prevData) => ({ 
                                ...prevData, 
                                firstName: res.data.first_name 
                            }));   
                        }

                        if(res.data.last_name !== null) {
                            setFormData((prevData) => ({ 
                                ...prevData, 
                                lastName: res.data.last_name 
                            }));
                        }

                        setShowEmailTextBox(false);
                    }else {
                        setShowEmailTextBox(true);
                    }
                } else {
                    setShowEmailTextBox(true);
                }
            }
        } catch (error) {
            setShowEmailTextBox(true);
            if(error.response?.status === 403){
                navigate("/assessment-submitted",  { 
                    state: { 
                        data:data, 
                        formData: formData
                    }
                });
            }else if(error.response?.status === 404){
                navigate("/assessment-not-found",  { 
                    state: { 
                        data:data, 
                        formData: formData
                    }
                });
            }
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        checkValidAssessment();
    });
    const handleSubmit = async () => {
        if ((formData.firstName === "" || formData.lastName === "" || !formData.accept) || (showEmailTextBox && formData.email === "")) {
            if(showEmailTextBox && formData.email === ""){
                setFormDataError((prevErrors) => ({ ...prevErrors, email: "Email is required" }));
            }
            if (formData.firstName === "") {
                setFormDataError((prevErrors) => ({ ...prevErrors, firstName: "First Name is required" }));
            }
            if (formData.lastName === "") {
                setFormDataError((prevErrors) => ({ ...prevErrors, lastName: "Last Name is required" }));
            }
            if (!formData.accept) {
                setFormDataError((prevErrors) => ({ ...prevErrors, accept: "You have to accept our privacy policy before you can start the assessment." }));
            }
            return false;
        }
        try {   
            setSubmitLoad(true);          
            const response = await axios.post(UpdateCandidateFirstAndLastName, formData);
            const res = response.data;
            setSubmitLoad(false);  
            if (res.status) {
                navigate("/exam-instruction",  { state:{ data:data, formData: formData}});
            }
        } catch (error) {
            setSubmitLoad(false);  
            console.error("Error fetching data:", error);
        }
    };
    const handelChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        setData(prevData => ({
            ...prevData,
            [event.target.name === 'lastName' ? 'last_name' : 'first_name']: event.target.value
        }));
        setFormDataError((prevErrors) => ({ ...prevErrors, [event.target.name]: "" }));
    };
    const handelChangeCheckBox = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.checked });
        setFormDataError((prevErrors) => ({ ...prevErrors, [event.target.name]: "" }));
    };
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f6f6f6",
                minHeight: "100vh",
                position: "relative",
            }}
        >
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Confirm Detail - TestnHire</title>
                        
                    </Helmet>
                </div>
            </HelmetProvider>
            <Container maxWidth="sm" style={{ backgroundColor: "white", padding: "0px",}}>
                <Box
                    style={{
                        padding: "55px",
                        boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                    }}
                >
                    <Grid style={{textAlign:"center"}}>
                        <img style={{textAlign:"center"}} src={Logo} alt="../../../../Assests/Images/FirstSectionImg.jpg" />
                    </Grid>
                    <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3, textAlign:"center"}}>
                        Could you please confirm your identity for me?
                    </Typography>
                    <Box sx={{ my: 2 }}>
                        <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField sx={{ width: "100%" }} label="First name" value={formData.firstName} name="firstName" onChange={handelChange} />
                            {formDataError.firstName && <span className="error_msg_style">{formDataError.firstName}</span>}
                        </ThemeProvider>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField sx={{ width: "100%" }} label="Last name" value={formData.lastName} name="lastName" onChange={handelChange} />
                            {formDataError.lastName && <span className="error_msg_style">{formDataError.lastName}</span>}
                        </ThemeProvider>
                    </Box>
                    {showEmailTextBox ? (
                        <Box sx={{ my: 2 }}>
                            <ThemeProvider theme={customTheme(outerTheme)}>
                                <TextField sx={{ width: "100%" }} label="Email" name="email" onChange={handelChange} />
                                {formDataError.email && <span className="error_msg_style">{formDataError.email}</span>}
                            </ThemeProvider>
                        </Box>
                    ) : (
                        <></>
                    )}
                    <Grid className="talentAssessmentCheck" sx={{ display: "flex", alignItems: "center" }}>
                        <Grid>
                            <Checkbox {...label} name="accept" onChange={handelChangeCheckBox} />
                        </Grid>
                        <Grid>
                            <Typography variant="body1" sx={{ fontSize: "13px", fontWeight: 500 }}>
                                I have ready and accept the <a href={process.env.REACT_APP_HOME_URL+"/legal-stuff?tab=privacy-policy"} target="_blank" style={{ color: "#46a997",textDecoration: "none" }}> privacy policy</a>  and <a href={process.env.REACT_APP_HOME_URL+"/legal-stuff?tab=terms-of-use"} target="_blank" style={{ color: "#46a997",textDecoration: "none" }}>candidates terms</a>
                            </Typography>
                            <Typography variant="body1" sx={{ fontSize: "12px", fontWeight: 400 }}>
                                {formDataError.accept && <span className="error_msg_style">{formDataError.accept}</span>}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid className="talentAssessmentCheck" sx={{ display: "flex", alignItems: "center" }}>
                        <Grid>
                            <Checkbox {...label} name="marketing" onChange={handelChangeCheckBox} />
                        </Grid>
                        <Grid>
                            <Typography variant="body1" sx={{ fontSize: "13px", fontWeight: 500 }}>
                                I agree to receive product,marketing,and job communication form testNhire
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            onClick={handleSubmit}
                            sx={{
                                ...btnStyle,
                                bgcolor: "var(--button-bg-color-assessment)",
                                minWidth: "48px",
                                minHeight: "48px",
                                textTransform: "capitalize",
                                width: "100%",
                                mt: 5,
                                mb: 2,
                                "&:hover": {
                                    bgcolor: "var(--button-hover-bg-color-assessment)",
                                },
                            }}
                            variant="contained"
                        >
                        {submitload?"Submitting...":"Submit"}    
                        </Button>
                    </Grid>
                </Box>
            <Grid sx={{textAlign:"center", background:"#f6f6f6", pt:5}}>
                <img style={{opacity:0.35 }} src={Logo} alt="../../../../Assests/Images/FirstSectionImg.jpg" />
            </Grid>
            </Container>
        </Box>
    );
}

export default Index;
