import React, { useState } from "react";
import { Box, Button,  Grid } from "@mui/material";
import QualifyComponent from "../QuestionForm/QualifyComponent";
import MCQSingle from "../QuestionForm/MCQSingle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Logo from "../../../../Assests/Images/LOGO.png";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import { SubmitTalentAssessmentDisqualified } from "../../../../Constants/ConstantsUrl";
import axios from "axios";

const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

const Index = (props) => {
    const navigate = useNavigate();
    const { quilifing, handelFinalSubmit, typeStep, oldData, onNextHandleFullScreen,candidateName,submitResponseOnEveryNext } = props;
    const [qualifyingLength] = useState(Object.keys(quilifing).length);
    const [qualifyingQuestion] = useState(Object.values(quilifing));
    const [skipComponent, setSkipComponent] = useState(true);
    const [progressQuestion, setProgressQuestion] = useState(0);
    const bufferQuestion = 0;
    const [attemptQuestion, setAttemptQuestion] = useState(0);
    const [result, setResult] = useState(0);
    const [selectedValue, setSelectedValue] = useState('');
    const [resultAnswer, setResultAnswer] = useState([]);
    const [wrongResultCount,setWrongResultCount] =useState(0);
    const [nextDisable,setNextDisable]=useState(true);

    const handleNext = () => {
        setSkipComponent(false);
    };
    const handleRadioChange = (event, id) => {
        if (selectedValue === event.target.value) {
            const q_id = id.id;
            const { [q_id]: omit, ...newResultAnswer } = resultAnswer;
            setResultAnswer(newResultAnswer);
            setSelectedValue("");
            setNextDisable(true);
        }
        else {
            const answer = atob(id.answer);
            const q_id = id.id;
            let resultGet = 0;
            if (answer === event.target.value) {
                resultGet = 1;
            }
            const answerArr = { question_id: q_id, answer_id: event.target.value, result: resultGet, completeTime: 0 };
            setResultAnswer({ ...resultAnswer, [q_id]: answerArr });
            setResult(resultGet);
            setSelectedValue(event.target.value);
            setNextDisable(false);
        }
        //old code
        // let answer = atob(qualifyingQuestion[attemptQuestion].question_details.questions[0].answer)
        // const q_id = id.id;
        // let resultGet = 0;
        // if(answer === event.target.value){
        //     resultGet = 1;
        // }
        // const answerArr = { 
        //     question_id: q_id, 
        //     answer_id: event.target.value, 
        //     result: resultGet, 
        //     completeTime:0 
        // };
        // setResultAnswer({ 
            //     ...resultAnswer, 
            //     [q_id]: answerArr 
            // });
            // setSelectedValue(event.target.value)
            // setResult(resultGet);
        };
    // old code
    // const handelNextSubmit = () => {
    //     onNextHandleFullScreen();
    //     let answerSubmitOnNext = {
    //         ...resultAnswer[qualifyingQuestion[attemptQuestion].question_details.questions[0].id],
    //         completeTime: 0,
    //         parent_question_id: qualifyingQuestion[attemptQuestion].question_id,
    //         question_type_id: qualifyingQuestion[attemptQuestion].question_type_id,
    //         question_category_id: qualifyingQuestion[attemptQuestion].question_category_id,
    //     }
    //     submitResponseOnEveryNext(answerSubmitOnNext);
    //     if(result === 1){
    //         const nextQueCount = attemptQuestion + 1;
    //         if(qualifyingLength > nextQueCount){
    //             setAttemptQuestion(nextQueCount);
    //         }else{
    //             handelFinalSubmit(typeStep, resultAnswer, quilifing);
    //         }
    //         setProgressQuestion( (nextQueCount / qualifyingLength) * 100 )
    //     }else{
    //         functionDisqualified();
    //     }
    // };
    const handelNextSubmit = async() => { //fuction will respond in asyc await
        onNextHandleFullScreen();
        let wrongAnswers = wrongResultCount;
        let answerSubmitOnNext = {
            ...resultAnswer[qualifyingQuestion[attemptQuestion].question_details.questions[0].id],
            completeTime: 0,
            parent_question_id: qualifyingQuestion[attemptQuestion].question_id,
            question_type_id: qualifyingQuestion[attemptQuestion].question_type_id,
            question_category_id: qualifyingQuestion[attemptQuestion].question_category_id,
        }
         
        await submitResponseOnEveryNext(answerSubmitOnNext);
        setNextDisable(true);
        const nextQueCount = attemptQuestion + 1;
        if(result !== 1){
            wrongAnswers = wrongAnswers + 1;
            setWrongResultCount((prev)=>prev+1);
        }
        if(qualifyingLength > nextQueCount){
            setAttemptQuestion(nextQueCount);
        }else{
            if(wrongAnswers>0){
                functionDisqualified();
            }else{
                handelFinalSubmit(typeStep, resultAnswer, quilifing);
            }
        }
        setProgressQuestion( (nextQueCount / qualifyingLength) * 100 )
    };
    const functionDisqualified = async () => {
        try {
            const sendArr = {
                parent_question_id: quilifing.question_id,
                question_type_id: quilifing.question_type_id,
                question_category_id: quilifing.question_category_id,
                result_sub_meta: {
                    ...resultAnswer
                },
            }
            const formsData = {
                invitation_token: oldData.token,
                answer: sendArr
            };
            await axios
                .post(SubmitTalentAssessmentDisqualified , formsData)
                .then((response) => response)
                .then((response) => {
                    navigate("/disqualified", { state:{ data:oldData}});
                });
        } catch (error) {
            console.log(error);
        }
    }
    
    const steps = (steps) => {
        return (
            <MCQSingle
                key={`Q${steps}`}
                mainKey={steps}
                id={steps}
                data={qualifyingQuestion[steps]}
                count={0}
                handleRadioChange={handleRadioChange}
                firstStepGo={1}
            />
        );
    }
        
    return (
        <>
                <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                    <Grid>
                        <img
                            style={{ padding: "20px" }}
                            src={Logo}
                            alt="../../../../Assests/Images/FirstSectionImg.jpg"
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: "30%",
                            padding: "20px",
                            my: "auto",
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <span style={{ fontSize: "13px" }}>{attemptQuestion}/{qualifyingLength}</span>
                        <LinearProgress
                            variant="determinate"
                            value={progressQuestion}
                            valueBuffer={bufferQuestion}
                            sx={{ flexGrow: 1, mx: 1 }}
                        />
                    </Grid>
                    <Grid sx={{ padding: "20px" }}>
                        <Button
                            onClick={skipComponent ? handleNext : handelNextSubmit}
                            sx={{
                                ...btnStyle,
                                bgcolor: "var(--button-bg-color-assessment)",
                                minWidth: "48px",
                                minHeight: "48px",
                                textTransform: "capitalize",
                                padding: "0px 30px",
                                "&:hover": {
                                    bgcolor: "var(--button-hover-bg-color-assessment)",
                                },
                            }}
                            variant="contained"
                            disabled={nextDisable}
                        >
                            Next
                            <ChevronRightIcon />
                        </Button>
                    </Grid>
                </Grid>
            {skipComponent ? (
                <QualifyComponent totalQualify={qualifyingLength} handleNext={handleNext} selectedValue={selectedValue} candidateName={candidateName} />
            ) : (
                <Box>
                    {steps(attemptQuestion)}     
                </Box>
            )}
        </>
    );
};
export default Index;
