import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Logo from "../../../Assests/Images/LOGO.png";
import { styled } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Paper from "@mui/material/Paper";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));
const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

function Index() {
    const navigate = useNavigate();
    const location = useLocation();
    const extraTime = location.state?.time;
    const oldData = location.state?.data;
    const [antiCheating, setAntiCheating] = useState(oldData.anti_chating_data);

    const handelSubmit = () => {
        if (antiCheating.is_snapshot_enable === true) {
            navigate("/camera-setup", { state: { time: extraTime, data: oldData } });
        } else {
            navigate("/questions", { state: { time: extraTime, data: oldData } });
        }
    };
    
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f6f6f6",
                minHeight: "100vh",
                position: "relative",
            }}
        >
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Extra Time - TestnHire</title>
                    </Helmet>
                </div>
            </HelmetProvider>
            <Container maxWidth="lg">
                <Box>
                    <Grid container spacing={0} style={{ justifyContent: "center" }}>
                        <Grid item xs={10} md={6}>
                            <Grid
                                container
                                spacing={0}
                                style={{ backgroundColor: "white", padding: "55px", boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809" }}
                            >
                                <Grid item xs={12} md={12}>
                                    <Item>
                                        <Typography variant="h2" sx={{ fontSize: "24px", fontWeight: 400 }}>
                                            <span style={{ fontSize: "22px", fontWeight: 700 }}>Extra time approved</span>
                                        </Typography>
                                        <Divider sx={{ mt: 1, borderWidth: "1px", borderColor: "green" }} />

                                        <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 3 }}>
                                            Based on details you gave while the setting up your assessment, you have been granted {extraTime}% extra time
                                        </Typography>
                                        <Divider sx={{ my: 3, borderWidth: "1px", borderColor: "#edeeef" }} />
                                        <Grid sx={{ display: "flex", justifyContent: "end" }}>
                                            <Button
                                                onClick={handelSubmit}
                                                sx={{
                                                    ...btnStyle,
                                                    bgcolor: "var(--button-bg-color-assessment)",
                                                    minWidth: "48px",
                                                    minHeight: "48px",
                                                    textTransform: "capitalize",
                                                    "&:hover": {
                                                        bgcolor: "var(--button-hover-bg-color-assessment)",
                                                    },
                                                }}
                                                variant="contained"
                                            >
                                                Continue <ChevronRightIcon />
                                            </Button>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Grid>
                <img
                    style={{
                        position: "fixed",
                        bottom: "10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        opacity: 0.35,
                    }}
                    src={Logo}
                    alt="../../../../Assests/Images/FirstSectionImg.jpg"
                />
            </Grid>
        </Box>
    );
}

export default Index;
