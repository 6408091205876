import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Logo from "../../../Assests/Images/LOGO.png";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));

function Index() {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f6f6f6",
                minHeight: "100vh",
                position: "relative",
            }}
        >
            <HelmetProvider>
                <Helmet>
                    <title>Assessment Not Found - TestnHire</title>
                </Helmet>
            </HelmetProvider>
            <Container maxWidth="lg">
                <Box>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            backgroundColor: "white",
                            padding: "55px",
                            boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                        }}
                    >
                        <Grid item xs={12} md={12}>
                            <Item sx={{ padding: "5px 15px" }}>
                                <Typography variant="body1" sx={{ fontSize: "24px", fontWeight: 700, mt: 2 }}>
                                    {/* Countdown: {seconds} seconds */}
                                    This assessment is not available, due to following below reason
                                </Typography>
                                <Typography variant="body1" sx={{ fontSize: "18px", fontWeight: 400, mt: 2 }}>
                                    <li>
                                        Link (URL) is <b>broken</b>
                                    </li>
                                    <li>
                                        Might be assessment already <b>submitted</b>
                                    </li>
                                    <li>
                                        Might be assessment is <b>expired</b>
                                    </li>
                                </Typography>
                                <Divider sx={{ mt: 3, borderWidth: "1px", borderColor: "#edeeef" }} />
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Grid>
                <img
                    style={{
                        position: "fixed",
                        bottom: "10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        opacity: 0.35,
                    }}
                    src={Logo}
                    alt="../../../../Assests/Images/FirstSectionImg.jpg"
                />
            </Grid>
        </Box>
    );
}

export default Index;
