import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Paper from "@mui/material/Paper";
import {TransitionsModal} from '../../CameraSetup/Index';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));
const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

// import useMediaQuery from "@mui/material/useMediaQuery";

function StartTestConfirm({ goToFullScreen,open,setOpen,mandatoryScreenErr }) {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f6f6f6",
                position: "relative",
            }}
        >
            <Grid sx={{ mt: 40 }} item xs={10} md={12}>
                <Grid container spacing={0} style={{ backgroundColor: "white", padding: "55px", boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809" }}>
                    <Grid item xs={12} md={12}>
                        <Item>
                            <Typography variant="h2" sx={{ fontSize: "24px", fontWeight: 400 }}>
                                <span style={{ fontSize: "22px", fontWeight: 700 }}>Confirmation</span>
                            </Typography>
                            <Divider sx={{ mt: 1, borderWidth: "1px", borderColor: "green" }} />

                            <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400, mt: 3 }}>
                                Click Start Test button to start test
                            </Typography>
                            <Divider sx={{ my: 3, borderWidth: "1px", borderColor: "#edeeef" }} />
                            <Grid sx={{ display: "flex", justifyContent: "end" }}>
                                <Button
                                    onClick={goToFullScreen}
                                    sx={{
                                        ...btnStyle,
                                        bgcolor: "var(--button-bg-color-assessment)",
                                        minWidth: "48px",
                                        minHeight: "48px",
                                        textTransform: "capitalize",
                                        "&:hover": {
                                            bgcolor: "var(--button-hover-bg-color-assessment)",
                                        },
                                    }}
                                    variant="contained"
                                    disabled={mandatoryScreenErr}
                                >
                                    Start Test <ChevronRightIcon />
                                </Button>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
            </Grid>
            <TransitionsModal open={open} setOpen={setOpen} from={2}/>
        </Box>
    );
}

export default StartTestConfirm;
