import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "../../../Assests/Images/LOGO.png";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));


function Index() {
    const location = useLocation();
    const oldData = location.state?.data;
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(10);
    useEffect(()=>{
        if (seconds > 0) {
            const timerId = setInterval(() => {
              setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
      
            return () => clearInterval(timerId);
          } else {
            navigate("/feedback", { state:{ data:oldData}}); 
             
          }
    },[seconds])

    return (
        
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f6f6f6",
                minHeight: "100vh",
                position: "relative",
            }}
        >
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Already Submit - TestnHire</title>
                        
                    </Helmet>
                </div>
            </HelmetProvider>
            <Container maxWidth="lg">
                <Box>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            backgroundColor: "white",
                            padding: "55px",
                            boxShadow:
                                "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                        }}
                    >
                        <Grid item xs={12} md={12}>
                            <Item sx={{ padding: "5px 15px" }}>                                 
                                <Typography
                                    variant="body1"
                                    sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}
                                >
                                    Countdown: {seconds} seconds
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}
                                >
                                    You have attempt this test previously, redirect after 10 seconds ...
                                </Typography>
                                <Divider sx={{ mt: 3, borderWidth: "1px", borderColor: "#edeeef" }} />                                 
                            </Item>
                        </Grid>
                        
                         
                    </Grid>
                </Box>
            </Container>
            <Grid>
                <img
                    style={{
                        position: "fixed",
                        bottom: "10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        opacity: 0.35,
                    }}
                    src={Logo}
                    alt="../../../../Assests/Images/FirstSectionImg.jpg"
                />
            </Grid>
        </Box>
    );
}

export default Index;
