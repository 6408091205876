import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// TalentAssessment Components
import ConfirmDetails from "./Components/TalentAssessment/ConfirmCandidate/Index";
import ExamInstruction from "./Components/TalentAssessment/ExamInstruction/Index";
import AssessmentSetup from "./Components/TalentAssessment/AssessmentSetup/Index";
import IntroVideo from "./Components/TalentAssessment/IntroVideo/Index";
import ExtraTime from "./Components/TalentAssessment/ExtraTime/Index";
import CameraSetup from "./Components/TalentAssessment/CameraSetup/Index";
import Questions from "./Components/TalentAssessment/StartTest/Index";
import FeedbackTest from "./Components/TalentAssessment/Questions/FeedbackTest/index";
import FinishTest from "./Components/TalentAssessment/Questions/FinishTest/index";
import OutroVideo from "./Components/TalentAssessment/Questions/OutroVideo/Index";
import AlreadySubmitted from "./Components/TalentAssessment/AlreadySubmitted/Index";
import AssessmentNotFound from "./Components/TalentAssessment/AssessmentNotFound/Index";
import Disqualified from "./Components/TalentAssessment/Disqualified/Index"; 
import { talentRoute } from "./Constants/ConstantsUrl";
import PublicAssessmentInvite from "./Components/PublicAssessmentInvite/Index";

const theme = createTheme({
    typography: {
        fontFamily: "Poppins, sans-serif",
    },
});
function Redirect() {
    window.location.replace(process.env.REACT_APP_HOME_URL);
    return <></>;
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="App">
                <Router basename="/">
                    <Routes>
                        {talentRoute ? (
                            <>
                                {/* TalentAssessment Route */}
                                <Route path="/assessment/add-user/public-assessment-invite/:id/:userInvitationToken" element={<PublicAssessmentInvite />} />
                                <Route path="/assessment-submitted" element={<AlreadySubmitted />} />
                                <Route path="/assessment-not-found" element={<AssessmentNotFound />} />
                                <Route path="/confirm-details/:token" element={<ConfirmDetails />} />
                                <Route path="/exam-instruction" element={<ExamInstruction />} />
                                <Route path="/assessment-details" element={<AssessmentSetup />} />
                                <Route path="/intro-video" element={<IntroVideo />} />
                                <Route path="/extra-time" element={<ExtraTime />} />
                                <Route path="/camera-setup" element={<CameraSetup />} />
                                <Route path="/questions" element={<Questions />} />
                                <Route path="/feedback" element={<FeedbackTest />} />
                                <Route path="/finish-test" element={<FinishTest />} />
                                <Route path="/outro-video" element={<OutroVideo />} />
                                <Route path="/disqualified" element={<Disqualified />} />
                            </>
                        ) : (
                            <></>
                        )}                         
                        <Route path="*" element={<Redirect />} />
                    </Routes>
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;
