import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Typography, Grid, Container, Divider } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));

export default function LoaderWithInfo() {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f6f6f6",
                minHeight: "100vh",
                position: "relative",
            }}
        >
            <Container maxWidth="sm">
                <Box>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            backgroundColor: "white",
                            padding: "55px",
                            boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                        }}
                    >
                        <Grid item xs={12} md={12}>
                            <Item sx={{ padding: "5px 15px" }}>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress sx={{ color: "var(--text-color-black)" }} />
                                </Box>
                                <Typography variant="h6" sx={{ fontSize: "18px", mt: 2 }}>
                                    Finalizing Data Upload.This may take several seconds.
                                </Typography>
                                <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 400 }}>
                                    (please don't referesh this page or close the tab.)
                                </Typography>
                                <Divider sx={{ mt: 3, borderWidth: "1px", borderColor: "#edeeef" }} />
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}
