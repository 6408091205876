import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));

function Index(props) {
    const {data, type, officialSteps} = props
    return (
        <Box
            sx={{ 
                justifyContent: "center",                 
                bgcolor: "#f6f6f6",
                minHeight: "90vh",
                position: "relative",
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                width:"100%"
            }}
        >
             
            {(type === "official") ? (
                <Container maxWidth="lg">
                    <Box>
                        <Grid sx={{ml:"50px"}} container spacing={0} style={{ justifyContent: "center" }}>
                            <Grid item xs={10} md={6}>
                                <Grid
                                    container
                                    spacing={0}
                                    style={{
                                        backgroundColor: "white",
                                        padding: "55px",
                                        boxShadow:
                                            "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                                    }}
                                >
                                    <Grid item xs={12} md={12}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                sx={{ fontSize: "14px", fontWeight: 400 }}
                                            >
                                                This is the next test: 
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{ fontSize: "14px", fontWeight: 400 }}
                                            > Total question : <b> {data[officialSteps]?.question_details.questions?.length}  </b>
                                            </Typography>
                                            <Typography
                                                variant="h2"
                                                sx={{ fontSize: "20px", fontWeight: 700, mt: 3 }}
                                            >
                                                {data[officialSteps]?.question}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{ fontSize: "14px", fontWeight: 400, mt: 3 }}
                                            >
                                                The actual test start now, Good luck!
                                            </Typography>
                                            <Divider
                                                sx={{
                                                    my: 2,
                                                    borderWidth: "1px",
                                                    borderColor: "#d9d9d9",
                                                }}
                                            />
                                            <Typography
                                                variant="body1"
                                                sx={{ fontSize: "14px", fontWeight: 400, mt: 3 }}
                                            >
                                                Please stay on this screen. The timer for your next test
                                                has <span style={{ fontWeight: 700 }}>started</span> and{" "}
                                                <span style={{ fontWeight: 700 }}>
                                                    cannot be paused
                                                </span>{" "}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            ) : (
                <Container maxWidth="lg">
                    <Box>
                        <Grid sx={{ml:"50px"}} container spacing={0} style={{ justifyContent: "center" }}>
                            <Grid item xs={10} md={6}>
                                <Grid
                                    container
                                    spacing={0}
                                    style={{
                                        backgroundColor: "white",
                                        padding: "55px",
                                        boxShadow:
                                            "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                                    }}
                                >
                                    <Grid item xs={12} md={12}>
                                        <Item>
                                            <Typography
                                                variant="body1"
                                                sx={{ fontSize: "14px", fontWeight: 400 }}
                                            >
                                                This is the next test:
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{ fontSize: "14px", fontWeight: 400 }}
                                            > Total question : <b> {data[officialSteps]?.question_details.questions.length}  </b>
                                            </Typography>
                                            <Typography
                                                variant="h2"
                                                sx={{ fontSize: "20px", fontWeight: 700, mt: 3 }}
                                            >
                                                Custom questions
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{ fontSize: "14px", fontWeight: 400, mt: 3 }}
                                            >
                                                The actual test start now, Good luck!
                                            </Typography>
                                            <Divider
                                                sx={{
                                                    my: 2,
                                                    borderWidth: "1px",
                                                    borderColor: "#d9d9d9",
                                                }}
                                            />
                                            <Typography
                                                variant="body1"
                                                sx={{ fontSize: "14px", fontWeight: 400, mt: 3 }}
                                            >
                                                Please stay on this screen. The timer for your next test
                                                has <span style={{ fontWeight: 700 }}>started</span> and{" "}
                                                <span style={{ fontWeight: 700 }}>
                                                    cannot be paused
                                                </span>{" "}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            )}


            <Grid></Grid>
        </Box>
    );
}

export default Index;
