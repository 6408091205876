import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

function Essay(props) {
    const { data, count, handleAnswerChange } = props;     
    return (
        <Container
            maxWidth="lg"
            style={{
                backgroundColor: "white",
                padding: "0px",
                borderRadius: "12px",
            }}
        >
            <Box
                style={{
                    padding: "55px",
                    boxShadow:
                        "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                            Question
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ fontSize: "18px", fontWeight: 500, mt: 2 }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: data?.question_details?.questions[count].question }} />
                        </Typography>
                    </Grid>
                    <Grid item style={{ padding: "0px 24px" }} xs={12} md={6}>
                        <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700, my: 3 }}>
                            Enter your answer here
                        </Typography>
                        <ReactQuill
                            name="question"
                            label=" For example: Kindly introduce yourself."
                            theme="snow"
                            // value={formData.question.trimStart()}
                            style={{ minHeight: "250px" }}
                            onChange={(e) => handleAnswerChange(e, data?.question_details.questions[count].id)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default Essay;
