import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "../../../../Assests/Images/LOGO.png";
import { styled } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Paper from "@mui/material/Paper";
import { useNavigate, useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));
const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

function Index() {
    const location = useLocation();
    const oldData = location.state?.data;
    const ExtraTimeData = location.state?.time;
    const [videoUrls, setVideoUrls] = useState([]);
    const navigate = useNavigate();
    
    useEffect(()=>{ 
        const videoFun=()=>{
            const video_url = oldData.intro_outro_video_path ?? '';            
            setVideoUrls(video_url); 
        }
        videoFun();
    },[])
    const handelNext = () => { 
        navigate("/feedback", {state:{time:ExtraTimeData, data:oldData}});
    };
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#f6f6f6",
                minHeight: "100vh",
                position: "relative",
            }}
        >
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Information Video - TestnHire</title>
                        
                    </Helmet>
                </div>
            </HelmetProvider>
            <Container maxWidth="lg">
                <Box>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            backgroundColor: "white",
                            padding: "55px",
                            boxShadow:
                                "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                        }}
                    >
                        <Grid item xs={12} md={12}>
                            <Item sx={{ padding: "5px 15px" }}>
                                <Typography
                                    variant="h2"
                                    sx={{ fontSize: "24px", fontWeight: 400,}}
                                >
                                    <span style={{ fontSize: "22px", fontWeight: 700 }}>
                                         
                                    </span>
                                </Typography>
                                <Divider sx={{ mt: 2, borderWidth: "1px", borderColor: "green" }} />

                                <Typography
                                    variant="body1"
                                    sx={{ fontSize: "14px", fontWeight: 400, mt: 2 }}
                                >
                                    We use camera images to ensure fairness for everyone. Make sure
                                    that you are in front of your camera.
                                </Typography>
                                <Divider sx={{ mt: 3, borderWidth: "1px", borderColor: "#edeeef" }} />                                 
                            </Item>
                        </Grid>
                        
                        <Grid item xs={12} md={12}>
                            <Item>
                                <Grid>
                                <div className="VideoInput">
                                    {videoUrls.outro_video_path?
                                    <> 
                                        <ReactPlayer width={'100%'} height='100%'
                                            url={videoUrls.outro_video_path}
                                            playing={true}
                                            muted={true}
                                            controls={true}
                                        /></>:
                                        <Typography className="video_not_found">Outro Video not found</Typography>
                                    }
                                </div>
                                </Grid> 
                                <Grid sx={{ display: "flex", justifyContent: "end" }}>
                                    <Button
                                        sx={{
                                            ...btnStyle,
                                            bgcolor: "var(--button-bg-color-assessment)",
                                            minWidth: "48px",
                                            minHeight: "48px",
                                            textTransform: "capitalize",
                                            mt: 5,
                                            mb: 2,
                                            "&:hover": {
                                                bgcolor: "var(--button-hover-bg-color-assessment)",
                                            },
                                        }}
                                        onClick={handelNext}
                                        variant="contained"
                                    >
                                        Next <ChevronRightIcon />
                                    </Button>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Grid>
                <img
                    style={{
                        position: "fixed",
                        bottom: "10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        opacity: 0.35,
                    }}
                    src={Logo}
                    alt="../../../../Assests/Images/FirstSectionImg.jpg"
                />
            </Grid>
        </Box>
    );
}

export default Index;
