import { Box, Button, Container, Divider, FormControlLabel, Grid, RadioGroup, Autocomplete, Typography, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import Logo from "../../../../Assests/Images/LOGO.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { useNavigate, useLocation } from "react-router-dom";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";
import TextField from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import axios from "axios";
import { SubmitTalentAssessmentFeedback, getJobRoleOp, AllCountry } from "../../../../Constants/ConstantsUrl";
import { getHeaders, getLanguage } from "../../../../../src/Constants/ConstantsUrl";
import { Helmet, HelmetProvider } from "react-helmet-async";

 
const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#0000001f",
                        "--TextField-brandBorderHoverColor": "#276678",
                        "--TextField-brandBorderFocusedColor": "#276678",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                            transition: "0.5s all",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            transition: "0.5s all",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&::before, &::after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&::before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });

const btnStyle = {
    color: "white",
    boxShadow: "unset",
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    boxShadow: "none",
    background: "none",
}));
const blue = {
    100: "var(--button-bg-color-assessment)",
    200: "#276678",
    400: "#276678",
    500: "var(--button-bg-color-assessment)",
    600: "#276678",
    900: "var(--button-bg-color-assessment)",
};

const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#276678",
    300: "#DC7D0D",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#276678",
    800: "#303740",
    900: "#1C2025",
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
    ({ theme }) => `
  
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 20px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 0 0 1px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const Index = (props) => {
    const isMobile = useMediaQuery("(max-width:960px)");
    const outerTheme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const oldData = location.state?.data;
    const [jobRole, setJobRole] = useState([]);
    const { errorMessage } = props;
    const [language, setLanguage] = useState([]);
    const [formData, setFormData] = useState({
        test_experience: "",
        answer: "",
        education_level: "",
        relevant_experience: "",
        study: "",
        current_role_experience: "",
        gender: "",
        country: "",
        first_language: "",
        born: "",
        ethnicity: "",
        country_raise:"",
    });
    const [allcoutry, setAllcountry] = useState({});
    const [allState, setAllState] = useState([]);
    const [feedbackLoader,setFeedbackLoader ] = useState(false);
    useEffect(() => {
        getJObRoleFun();
        getLanguageFun();
        getAllCountryFun();
    }, []);
    const handleButtonClick = async () => {
        setFeedbackLoader(true);
        const formDataAll = new FormData();
        formDataAll.append("invitation_id", oldData?.invitation_id);
        formDataAll.append("test_experience", formData.test_experience);
        formDataAll.append("answer", formData.answer);
        formDataAll.append("education_level", formData.education_level);
        formDataAll.append("relevant_experience", formData.relevant_experience);
        formDataAll.append("study", formData.study);
        formDataAll.append("current_role_experience", formData.current_role_experience);
        formDataAll.append("gender", formData.gender);
        formDataAll.append("country", (formData.country !== '' && formData.country !== undefined && formData.country !== null)?formData.country?.name:"");
        formDataAll.append("first_language", formData.first_language);
        formDataAll.append("born", formData.born);
        formDataAll.append("ethnicity", (formData.ethnicity !== '' && formData.ethnicity !== undefined && formData.ethnicity !== null)?formData.ethnicity?.name:"");
        formDataAll.append("country_raise", (formData.country_raise !== '' && formData.country_raise !== undefined && formData.country_raise !== null)?formData.country_raise?.name:"");
        console.log(formData.ethnicity,"====formData.country !== ''", formData);
        try {
            const response = await axios.post(SubmitTalentAssessmentFeedback, formDataAll);
            const res = response.data;
            setFeedbackLoader(false);
            if (res.status=== 200) {
                navigate("/finish-test");
            }
        } catch (error) {
            setFeedbackLoader(false);
            console.error("Error fetching data:", error);
        }
    };
    const handelTextAnswer = async (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });         
    };
    const handelTextAnswerCountry=async(country_name)=>{
        //return false;
        try {
            const data = {
                country: country_name.name,
            };
            await axios
                .post("https://countriesnow.space/api/v0.1/countries/states", data)
                .then((response) => response)
                .then((response) => {
                    if (response.data.error === false) {
                        setAllState(response.data.data.states);
                    }
                    if (response.data.error === true) {
                        setAllState([]);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }
    const getAllCountryFun = async () => {
        try {
            await axios
                .get(AllCountry)
                .then((response) => response)
                .then((response) => {
                    if (response.data.status === 200) {
                        setAllcountry(response.data.data);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };
    const getJObRoleFun = async () => {
        try {
            await axios
                .get(getJobRoleOp)
                .then((response) => response)
                .then((response) => {
                    if (response.data.status === 200) {
                        setJobRole(response.data.data);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };
    const getLanguageFun = async () => {
        try {
            const headers = getHeaders();
            await axios
                .get(getLanguage, { headers })
                .then((response) => response)
                .then((response) => {
                    if (response.data.status === 200) {
                        setLanguage(response.data.data);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };
    const handleJobRoleChange = (event, value) => {
        setFormData({ ...formData, relevant_experience: value?.id });
    };
    const handlelangChange = (event, value) => {
        setFormData({ ...formData, first_language: value?.id });
    };
    
    const isOptionEqualToValue = (option, value) => {
        return option.id === value.id; // Compare based on the id property
    };

    const optionss = [
        {
            "id": 0,
            "code": "",
            "name": "",
            "slug": ""
        }
      ];
      const defaultValue = optionss[0];
    return (
        <Box>
            <HelmetProvider>
                    <Helmet>
                        <title>Feedback Form - TestnHire</title>
                    </Helmet>
            </HelmetProvider>
            <Container maxWidth="xl">
                <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                    <Grid>
                        <img style={{ padding: "20px" }} src={Logo} alt="../../../../Assests/Images/FirstSectionImg.jpg" />
                    </Grid>
                </Grid>
            </Container>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#f6f6f6",
                    minHeight: "90vh",
                    position: "relative",
                }}
            >
                <Container maxWidth="md" style={{ backgroundColor: "white", padding: "0px", margin: "40px" }}>
                    <Box
                        style={{
                            padding: isMobile ? "24px": "55px",
                            boxShadow: "0 6px 7px -4px #8888880f, 0 11px 15px 1px #8888880b, 0 4px 20px 3px #88888809",
                        }}
                    >
                        <Typography variant="h2" sx={{ fontSize: "22px", fontWeight: 700 }}>
                            You have now finished your assessment
                        </Typography>
                        <Divider
                            sx={{
                                borderWidth: "1px",
                                borderColor: "var(--button-bg-color-assessment)",
                                my: 1,
                            }}
                        />
                        <Typography variant="h2" sx={{ fontSize: "18px", fontWeight: 700, mt: 3 }}>
                            How was your experience?
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: "13px", fontWeight: 400, mt: 1 }}>
                            {" "}
                            rating the assessment will help testnhire improve subsequent assessments
                        </Typography>

                        <RadioGroup sx={{ my: 2 }} row aria-label="radio-group-english" name="test_experience" onChange={handelTextAnswer}>
                            <Grid justifyContent={"space-between"} container spacing={0}>
                                <Grid item xs={12} md={2} className={formData.test_experience === "terrible" ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}>
                                    <FormControlLabel
                                        sx={{
                                            width: "100%",
                                            margin: "auto",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            border: "1px solid #d5d4d4",
                                            // p: 1,
                                            // borderRadius: "8px",
                                            // my: 1,
                                        }}
                                        value="terrible"
                                        control={<Radio sx={{color:"#ededed"}} />}
                                        label={
                                            <>
                                                <Grid sx={{ margin: "auto", textAlign: "center" }}>
                                                    <SentimentDissatisfiedIcon />
                                                </Grid>
                                                <Grid sx={{ textAlign: "center" }}>
                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        Terrible
                                                    </span>
                                                </Grid>
                                            </>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} className={formData.test_experience === "bad" ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}>
                                    <FormControlLabel
                                        sx={{
                                            width: "100%",
                                            margin: "auto",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            border: "1px solid #d5d4d4",
                                            p: 1,
                                            borderRadius: "8px",
                                            my: 1,
                                        }}
                                        value="bad"
                                        control={<Radio sx={{color:"#ededed"}} />}
                                        label={
                                            <>
                                                <Grid sx={{ margin: "auto", textAlign: "center" }}>
                                                    <SentimentVeryDissatisfiedIcon />
                                                </Grid>
                                                <Grid sx={{ textAlign: "center" }}>
                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        Bad
                                                    </span>
                                                </Grid>
                                            </>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} className={formData.test_experience === "ok" ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}>
                                    <FormControlLabel
                                        sx={{
                                            width: "100%",
                                            margin: "auto",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            border: "1px solid #d5d4d4",
                                            p: 1,
                                            borderRadius: "8px",
                                            my: 1,
                                        }}
                                        value="ok"
                                        control={<Radio sx={{color:"#ededed"}} />}
                                        label={
                                            <>
                                                <Grid sx={{ margin: "auto", textAlign: "center" }}>
                                                    <TagFacesIcon />
                                                </Grid>
                                                <Grid sx={{ textAlign: "center" }}>
                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        OK
                                                    </span>
                                                </Grid>
                                            </>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} className={formData.test_experience === "good" ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}>
                                    <FormControlLabel
                                        sx={{
                                            width: "100%",
                                            margin: "auto",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            border: "1px solid #d5d4d4",
                                            p: 1,
                                            borderRadius: "8px",
                                            my: 1,
                                        }}
                                        value="good"
                                        control={<Radio sx={{color:"#ededed"}} />}
                                        label={
                                            <>
                                                <Grid sx={{ margin: "auto", textAlign: "center" }}>
                                                    <SentimentSatisfiedAltIcon />
                                                </Grid>
                                                <Grid sx={{ textAlign: "center" }}>
                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        Good
                                                    </span>
                                                </Grid>
                                            </>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} className={formData.test_experience === "great" ? "talentRadioBtnChecked" : "talentRadioBtnUnChecked"}>
                                    <FormControlLabel
                                        sx={{
                                            width: "100%",
                                            margin: "auto",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            border: "1px solid #d5d4d4",
                                            p: 1,
                                            borderRadius: "8px",
                                            my: 1,
                                        }}
                                        value="great"
                                        control={<Radio sx={{color:"#ededed"}} />}
                                        label={
                                            <>
                                                <Grid sx={{ margin: "auto", textAlign: "center" }}>
                                                    <AddReactionOutlinedIcon />
                                                </Grid>
                                                <Grid sx={{ textAlign: "center" }}>
                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        Great
                                                    </span>
                                                </Grid>
                                            </>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </RadioGroup>

                        <TextareaAutosize
                            sx={{ width: "100%", borderRadius: "8px", minHeight: "100px", maxWidth: "790px", maxHeight: "200px" }}
                            aria-label="empty textarea"
                            placeholder="Please tell us more about your answer"
                            name="answer"
                            onChange={handelTextAnswer}
                        />
                        <Grid item sx={{ mt: 2 }} container spacing={0}>
                            <Grid item xs={12} md={12}>
                                <Item sx={{ bgcolor: "#f6f6f6", p: !isMobile ? "24px": "10px" }}>
                                    <Typography
                                        sx={{
                                            bgcolor: "#c8d7de",
                                            p: 2,
                                            fontWeight: 400,
                                            fontSize: "14px",
                                        }}
                                    >
                                        To help make sure that our tests provide equal opportunities for everyone, we statistically analyze test results anonymously. By sharing this information about
                                        your background, you help us improve our tests for all candidates. This information will not be shared with your potential employer. Sharing this information
                                        with us is optional, and anything shared will be held in compliance with our privacy policy.
                                    </Typography>

                                    <Grid container spacing={0} sx={{ my: 2 }}>
                                        <Grid item sx={{ pr: isMobile ? "0": "8px" }} xs={12} md={6}>
                                            <Box>
                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-required-label">Highest education level</InputLabel>
                                                        <Select
                                                            
                                                            
                                                            value={formData.education_level}
                                                            name="education_level"
                                                            onChange={handelTextAnswer}
                                                            label="Highest education level"
                                                        >
                                                            <MenuItem value={"some_high_school"}>Some High School</MenuItem>
                                                            <MenuItem value={"high_school_diploma_ged"}>High School Diploma / GED</MenuItem>
                                                            <MenuItem value={"some_college"}>Some College</MenuItem>
                                                            <MenuItem value={"associate_degree"}>Associate Degree</MenuItem>
                                                            <MenuItem value={"bachelors_degree"}>Bachelor's Degree</MenuItem>
                                                            <MenuItem value={"masters_degree_or_higher"}>Master's Degree or Higher</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>

                                        <Grid item sx={{ pl: isMobile ? "0": "8px",mt: isMobile ? "16px": "0px" }} xs={12} md={6}>
                                            <Box>
                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                    <TextField sx={{ width: "100%" }} label="What did you study?" onChange={handelTextAnswer} name="study" value={formData.study} />
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0} sx={{ my: 2 }}>
                                        <Grid item sx={{ pr: isMobile ? "0": "8px" }} xs={12} md={6}>
                                            <Box>
                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={jobRole}
                                                        value={jobRole?.find((role) => role.id === formData.relevant_experience) || null}
                                                        getOptionLabel={(option) => option.title.replace(/&amp;/g, "&") ?? "select Job Role"}
                                                        onChange={handleJobRoleChange}
                                                        name="relevant_experience"
                                                        sx={{
                                                            width: "100%",
                                                            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                                borderRadius: "4px",
                                                                borderColor: errorMessage?.name_of_assessment ? "red" : "#276678",
                                                                transition: "green 0.3s ease-in-out",
                                                                "&:hover": {
                                                                    borderColor: "#276678",
                                                                },
                                                            },
                                                            "& #combo-box-demo": {
                                                                color: "var(--text-color-black)",
                                                            },
                                                            "&:focus-within .MuiInputLabel-root": {
                                                                color: errorMessage?.name_of_assessment ? "red" : "#276678",
                                                            },
                                                        }}
                                                        isOptionEqualToValue={isOptionEqualToValue} // Pass the comparison function
                                                        renderInput={(params) => <TextField {...params} label="Most relevant experience" />}
                                                    />
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>

                                        <Grid item sx={{ pl: isMobile ? "0": "8px",mt: isMobile ? "16px": "0px"  }} xs={12} md={6}>
                                            <Box>
                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        label="year of experience role"
                                                        onChange={handelTextAnswer}
                                                        name="current_role_experience"
                                                        value={formData.current_role_experience}
                                                        type="number"
                                                    />
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0} sx={{ my: 2 }}>
                                        <Grid item sx={{ pr: isMobile ? "0": "8px" }} xs={12} md={6}>
                                            <Box>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                                    <Select
                                                        labelId="demo-customized-select-label"
                                                        id="demo-customized-select"
                                                        
                                                        value={formData.gender}
                                                        label="Gender"
                                                        name="gender"
                                                        onChange={handelTextAnswer}
                                                    >
                                                        <MenuItem value={"Male"}>Male</MenuItem>
                                                        <MenuItem value={"Female"}>Female</MenuItem>
                                                        <MenuItem value={"Other"}>Other</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>

                                        <Grid item sx={{pl: isMobile ? "0": "8px",mt: isMobile ? "16px": "0px"  }} xs={12} md={6}>
                                            <Box>
                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                    <TextField sx={{ width: "100%" }}
                                                     label="What year were you born?" 
                                                     onChange={handelTextAnswer} 
                                                     name="born"
                                                     type="number"
                                                    value={formData.born} />
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0} sx={{ my: 2 }}>
                                        <Grid item sx={{ pr: isMobile ? "0": "8px" }} xs={12} md={6}>
                                            <Box>
                                                <Autocomplete
                                                    name="country"
                                                    value={(formData?.country)? formData?.country:''}
                                                    onChange={(event, newValue) => { 
                                                        setFormData({ ...formData, country: newValue, ethnicity: {
                                                            "name": "",
                                                            "state_code": ""
                                                        }});
                                                        handelTextAnswerCountry(newValue);
                                                        }}
                                                    options={(allcoutry.length > 0)?allcoutry:[]}
                                                    getOptionLabel={(option) => (option.name)?option.name:''}
                                                    renderInput={(params) => <TextField {...params} label="Country/Region of Residence" />}
                                                    
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item sx={{ pl: isMobile ? "0": "8px",mt: isMobile ? "16px": "0px"  }} xs={12} md={6}>
                                            <Box>
                                                <Autocomplete
                                                    name="ethnicity"
                                                    value={(formData?.ethnicity)? formData?.ethnicity:defaultValue}
                                                    onChange={(event, newValue) => { 
                                                        setFormData({ ...formData, ethnicity: newValue });
                                                        }}
                                                    options={(allState.length > 0)?allState:[]}
                                                    getOptionLabel={(option) => option.name}
                                                    isOptionEqualToValue={isOptionEqualToValue} // Pass the comparison function
                                                    renderInput={(params) => <TextField {...params} label="Ethnicity" />}
                                                    disabled={formData.country=== "" || formData.country=== null ? true :false}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0} sx={{ my: 2 }}>
                                        <Grid item sx={{ pr: isMobile ? "0": "8px" }} xs={12} md={6}>
                                            <Box>
                                                <Autocomplete
                                                    name="country_raise"
                                                    value={(formData?.country_raise)? formData?.country_raise:defaultValue}
                                                    onChange={(event, newValue) => { 
                                                        setFormData({ ...formData, country_raise: newValue });
                                                        }}
                                                    options={(allcoutry.length > 0)?allcoutry:[]}
                                                    getOptionLabel={(option) => option.name}
                                                    isOptionEqualToValue={isOptionEqualToValue} // Pass the comparison function
                                                    renderInput={(params) => <TextField {...params} label="In What Country were you raised?" />}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sx={{ pl: isMobile ? "0": "8px",mt: isMobile ? "16px": "0px"  }} xs={12} md={6}>
                                            <Box>
                                                <ThemeProvider theme={customTheme(outerTheme)}>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={language}
                                                        getOptionLabel={(option) => option.name ?? "select language"}
                                                        label="What is your first language"
                                                        onChange={handlelangChange}
                                                        value={language?.find((role) => role.id === formData.first_language) || null}
                                                        sx={{
                                                            width: "100%",
                                                            "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                                                                borderRadius: "4px",
                                                                borderColor: errorMessage?.name_of_assessment ? "red" : "#276678",
                                                                transition: "green 0.3s ease-in-out",
                                                                "&:hover": {
                                                                    borderColor: "#276678",
                                                                },
                                                            },
                                                            "& #combo-box-demo": {
                                                                color: "var(--text-color-black)",
                                                            },
                                                            "&:focus-within .MuiInputLabel-root": {
                                                                color: errorMessage?.name_of_assessment ? "red" : "#276678",
                                                            },
                                                        }}
                                                        isOptionEqualToValue={isOptionEqualToValue} // Pass the comparison function
                                                        renderInput={(params) => <TextField {...params} label="What is your First Language" />}
                                                    />
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Item>
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderWidth: "1px", borderColor: "#edeeef", my: 3 }} />

                        <Grid container spacing={0}>
                            <Grid item sx={{ justifyContent: "end", display: "flex" }} xs={12} md={12}>
                                <Button
                                    onClick={handleButtonClick}
                                    sx={{
                                        ...btnStyle,
                                        bgcolor: "var(--button-bg-color-assessment)",
                                        minWidth: "48px",
                                        minHeight: "48px",
                                        textTransform: "capitalize",
                                        mt: 5,
                                        mb: 2,
                                        "&:hover": {
                                            bgcolor: "var(--button-hover-bg-color-assessment)",
                                        },
                                    }}
                                    variant="contained"
                                >
                                {feedbackLoader?"Submitting...":"Submit"} <ChevronRightIcon />
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        ></Grid>
                    </Box>
                </Container>
                <Grid>
                    <img
                        style={{
                            position: "fixed",
                            bottom: "10px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            opacity: 0.35,
                        }}
                        src={Logo}
                        alt="../../../../Assests/Images/FirstSectionImg.jpg"
                    />
                </Grid>
            </Box>
        </Box>
    );
};

export default Index;
